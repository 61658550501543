import React, { Component } from 'react';
import getBrowserGeolocation from '../util/geolocation';
import MapWithADrawingManager from './MapWithADrawingManager';
import EmpreendimentoService from '../services/empreendimentosServices';
import EmpreendimentoServiceCrawler from "../services/empreendimentosServicesCrawler";
import SlidingPaneEmpreendimento from './SlidingPanelEmpreendimento';
import colors from '../util/colors';
import FormFilters from '../components/FormFilters';
import geolocationService from '../services/geolocationService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './Loader';
import MouseTooltip from 'react-sticky-mouse-tooltip';
import AlertModal from "./AlertModal";
import { Button, Modal } from 'react-bootstrap';
import ToolsBar from './ToolsBar';
import HeatMapLegend from './HeatMapLegend';
import PolosGeradoresService from '../services/PolosGeradoresService';
import ModalPolosGeradores from './ModalPolosGeradores';
import ModalChangeShapeName from './ModalChangeShapeName';
import ReloadBuildingsWithoutMetaData from './FuncoesMapaPrimario/ReloadBuildingsWithoutMetaData';
import percentile from "percentile";
import {
    getDomiciliosPorFaixaDeRenda,
    getDomiciliosPorNumeroMoradores,
    getDomiciliosPorTipo,
    getDomiciliosPorTipoOcupacao,
    getFaixasEtarias,
    getPopulacaoPorFaixaDeRenda,
    getSetores,
    getSetoresWithHouldhouseIncome,
    getSetoresWithVerticalizationIndex,
    initializeColors,
    getSetoresWithPropertyIndex,
    getSetoresWithPopulationDensity,
    getSetoresWithPopulationDensityCenso2022
} from '../services/rendaIbgeService';
import RendaIbgeLegend from "./RendaIbgeLegend";
import circleToPolygon from 'circle-to-polygon';
import SocioDemografiaModal from "./SocioDemografia/SocioDemografiaModal";
import md5 from "md5";
import ModalRaioPorDeslocamento from "./RaioPorDeslocamento/ModalRaioPorDeslocamento";
import { Sidebar } from 'primereact/sidebar';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Legenda from "./Legenda";
import Ferramentas from "./Ferramentas/Ferramentas";
import { replaceMarkersRevendaLocacao } from "../util/RevendaLocacaoUtil";
import { convertPolygonToGeojson, getPolygonPath } from "../util/geoUtil";
import SimpleEmpreendimentosService from '../services/SimpleEmpreendimentosService';
import { defaultCircleOptions, defaultPolygonOptions, } from "./mapsDefaultOptions";
import KmlService from "../services/KmlService";
import ModalSalvarProjeto from "./Projeto/ModalSalvarProjeto";
import ShapesAttributesEditor from "./ShapesAttributesEditor/ShapesAttributesEditor";
import ModalLegendaRendaIbge from "./ModalLegendaRendaIbge/ModalLegendaRendaIbge";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import configSociodemografia from "./SocioDemografia/config";
import { fill } from 'lodash';

class DefaultMap extends Component {

    initialState = {
        empreendimentos: [],
        empreendimentosDoMes: [],
        currentPosition: { lat: -25.551136, lng: -49.1929618 },
        isSlideOpen: false,
        currentShowing: undefined,
        tipo_empreendimento: [],
        tipo_negocio: [],
        status: [{ "value": "Comercialização", "label": "Comercialização" }],
        polygons: [],
        circles: [],
        markers: [],
        overlays: [],
        revendaLocacao: [],
        area_maior_que: '',
        area_menor_que: '',
        porcentagem_vendida_minima: '',
        porcentagem_vendida_maxima: '',
        padrao: [],
        panel_empreendimento_visible: true,
        loadingMetadata: false,
        modal_empreendimento_visible: true,
        valor_maior_que: '',
        valor_menor_que: '',
        valor_metro_maior_que: '',
        valor_metro_menor_que: '',
        raio: 3000,
        cidade: [],
        estado: [],
        bairros: [],
        pontos: [],
        ano_lancamento: [],
        mes_ano_lancamento: '',
        estagio: [],
        incorporadoras: [],
        loading_empreendimento: false,
        fillColor: 'green',
        selectedIcon: undefined,
        showInfoWindow: false,
        alreadyDisplayed: false,
        showTooltip: false,
        radiusChanging: '',
        countRectangles: 0,
        countPolygons: 0,
        zoom: 15,
        nome_empreendimento: [],
        address: '',
        bairrosOptions: [],
        quartos: [],
        vagas: [],
        juros_maximo: '',
        juros_minimo: '',
        valor_parcela_minima: '',
        valor_parcela_maxima: '',
        parcelas_minimo: '',
        parcelas_maximo: '',
        ranges: [],
        kml_bairros: [],
        kml_layers: [],
        kmls_to_show: [],
        showKMl: false,
        showInfo: false,
        showProjectName: false,
        projeto: '',
        project_name: null,
        project_share_users: [],
        projetos: [],
        heatMap: null,
        show_polos_geradores: false,//mostrar polos geradores
        polos_geradores: [],
        show_modal_polos_geradores: false,
        polos_geradores_types_selected: [],
        showMarkers: true,//mostrar marcadores de empreendimentos
        showResaleMarkers: false,//mostrar marcadores de revenda e locacao
        showResaleOrLocationMarkers: false,//mostrar marcadores de revenda e locacao
        showLocationMarkers: false,//mostrar marcadores de revenda e locacao
        polos_geradores_current_showing: [],
        polos_geradores_current_grouped: [],
        polos_geradores_current_cache_key: '',//chave de cache para os polos geradores, usado para exportar os polos geradores em Excel no Backend
        estadosSelecionados: null,
        busca_por_estado: false,
        medianIntensity: 0,
        heatMapConfig: {},
        show_modal_change_shape_attributes: false,
        current_shape_editing: null,
        heatMapLegendConfig: [],
        use_cluster: false,
        showRenda: false,
        rendas_por_area: [],
        faixa_etaria_data: [],
        loading_dados_socio_demografia: false,
        show_modal_dados_socio_demografia: false,
        dados_senso_demografico: {},
        show_modal_raio_de_deslocamento: false,
        left_side_bar_visible: true,
        active_tab: 'filtros',
        loadingSaveProject: false,
        errors_projeto: [],
        setoresColorRanges: [
            { min: 0, max: 1000, color: '#a1d070' },
            { min: 1001, max: 2000, color: '#ffff00' },
            { min: 2001, max: 3000, color: '#80ff00' },
            { min: 3001, max: 4000, color: '#00ff00' },
            { min: 4001, max: 5000, color: '#00ff80' },
            { min: 5001, max: 6000, color: '#ff7000' },
            { min: 6001, max: 7000, color: '#00ffff' },
            { min: 7001, max: 8000, color: '#0080ff' },
            { min: 8001, max: 9000, color: '#0000ff' },
            { min: 9001, max: 10000, color: '#8000ff' },
            { min: 10001, max: 11000, color: '#ff00ff' },
            { min: 11001, max: 12000, color: '#ff0080' },
            { min: 12001, max: 13000, color: '#FFC0CB' },
            { min: 13001, max: 14000, color: '#008080' },
            { min: 14001, max: 15000, color: '#800000' },
            { min: 15001, max: Infinity, color: '#556B2F' }
        ],
        show_modal_customizar_cores_setores: false,
        mapa_coropetico_current_showing: null,
        color_ranges: {

        }
    }

    constructor() {
        super();
        this.state = this.initialState;
    }

    infoWindow = null;
    markers = [];
    markersClusterer = null;

    toggleShowMarkers = () => {
        this.setState({
            showMarkers: !this.state.showMarkers
        })
    }


    editShapeName = (shape) => {
        this.setState({
            ...this.state,
            show_modal_change_shape_attributes: !this.state.show_modal_change_shape_attributes,
            current_shape_editing: shape
        })

        if ($('#results-icon').hasClass('fa-angle-double-right')) {
            $("#btn-results").click();
        }

    }

    updateRendaColorRange = (colorRange, index) => {
        let colorRanges = this.state.color_ranges[this.state.mapa_coropetico_current_showing.type];

        //se index for -1 adiciona no final do array (Caso de quando está sendo adicionado um novo range)
        if (index === -1) {
            colorRanges.push(colorRange);
        } else {
            colorRanges[index] = colorRange;
        }

        this.setState({
            ...this.state,
            color_ranges: {
                ...this.state.color_ranges,
                [this.state.mapa_coropetico_current_showing.type]: [...colorRanges]
            }
        });
    }

    removeRendaColorRange = (index) => {
        let colorRanges = this.state.color_ranges[this.state.mapa_coropetico_current_showing.type];

        colorRanges.splice(index, 1);

        this.setState({
            ...this.state,
            color_ranges: {
                ...this.state.color_ranges,
                [this.state.mapa_coropetico_current_showing.type]: [...colorRanges]
            }
        });
    }

    setShapeName = async (name) => {
        let shape = this.state.current_shape_editing;

        shape.name = name;


        await this.setState({
            ...this.state, current_shape_editing: shape
        });
    }

    updateShape = async (shape) => {

        let circles = this.state.circles;
        let overlays = this.state.overlays;

        for (let i = 0; i < overlays.length; i++) {
            if (overlays[i].id === shape.id) {
                overlays[i] = shape;
            }
        }

        if (shape.type === 'Círculo') {
            for (let i = 0; i < circles.length; i++) {
                if (circles[i].id === shape.id) {
                    circles[i] = shape;
                }
            }
        }

        await this.setState({
            ...this.state,
            overlays: [...overlays],
            circles: [...circles],
            show_modal_change_shape_attributes: !this.state.show_modal_change_shape_attributes,
            current_shape_editing: null
        });

        if ($('#btn-results').hasClass('fa-angle-double-right')) {
            $("#btn-results").click();

        }
    }


    //carrega kmls de bairros
    loadKml = async () => {
        try {

            let cidadesComKmlBairros = this.state.kml_bairros;

            if (cidadesComKmlBairros.length === 0) {
                return;
            }

            let kmls_to_show = [];
            for (let i = 0; i < cidadesComKmlBairros.length; i++) {
                let kmlFile = this.state.kml_bairros[i];
                kmls_to_show.push(kmlFile.kml);

            }

            await this.setState({
                ...this.state, kmls_to_show: [...kmls_to_show],
            });

        } catch (e) {
            console.log(e);
        }
    }

    openModalLegendaSetores = async () => {
        await this.setState({
            ...this.state,
            show_modal_customizar_cores_setores: true,
            left_side_bar_visible: false
        });

        if ($('#results-icon').hasClass('fa-angle-double-right')) {
            $("#btn-results").click();
        }
    }

    closeModalLegendaSetores = async () => {
        await this.setState({
            ...this.state,
            show_modal_customizar_cores_setores: false
        });
    }

    formatInfoWindowDatum = (type, value) => {

        let formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });

        let doubleFormatter = new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            maximumFractionDigits: 2
        });


        if (type === 'currency') {
            return formatter.format(value);
        }

        if (type === 'percentage') {
            return formatter.format(value).replace("R$", "") + '%';
        }

        if (type === 'integer') {
            return new Intl.NumberFormat('pt-BR').format(value);
        }

        if (type === 'double') {
            return doubleFormatter.format(value);
        }


        if (type === 'km2') {
            let formatter = new Intl.NumberFormat('pt-BR', {
                style: 'decimal',
                maximumFractionDigits: 6
            });

            return formatter.format(value);
        }



        return value;

    }


    showIbgeInfo = async (tipoInfo, data) => {

        let configOptions = configSociodemografia[tipoInfo];

        await this.removeIbgeDataLayer();

        await this.setState({
            ...this.state,
            showRenda: false,
            loading: true
        });

        try {
            let map = window.googleMap.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;

            let dataLayer = new google.maps.Data();
            dataLayer.addGeoJson(data.geojson)
            dataLayer.setMap(map);
            window.ibge_layer = dataLayer;

            dataLayer.setStyle(function (feature) {

                return {
                    fillColor: feature.getProperty("color"),
                    fillOpacity: 0.9,
                    strokeWeight: 1,
                    strokeColor: '#000000',
                    strokeOpacity: 0.5
                };
            });


            dataLayer.addListener('click', (event) => {
                let feature = event.feature;

                let body = '';

                for (let i = 0; i < configOptions.dados_info_window.length; i++) {
                    let datum = configOptions.dados_info_window[i];
                    let value = feature.getProperty(datum.field);

                    let formattedValue = this.formatInfoWindowDatum(datum.type, value);

                    body += `
                        <div class="info-window-item">
                            <span class="info-window-item-label">${datum.label}:</span>
                            <span class="info-window-item-value">${formattedValue}</span>
                        </div>
                    `;
                }

                let content = `
                    <div class="info-window">
                        <div class="info-window-header">
                            <h5 class="info-window-title">Setor Censitário</h5>
                            <h6 class="info-window-subtitle">${feature.getProperty('codigo_setor')}</h6>
                        </div>
                        <div class="info-window-body">
                          ${body}
                        </div>
                    </div>
                `;

                let infoWindow = new google.maps.InfoWindow({
                    content: content
                });

                infoWindow.setPosition(event.latLng);
                infoWindow.open(map);


            });


            await this.setState({
                ...this.state,
                ibge_info: data,
                showRenda: true,
                ibgeDataLayer: dataLayer,
                left_side_bar_visible: true,
                active_tab: 'legendas',
                setoresColorRanges: configOptions.color_ranges
            });

        } catch (error) {
            console.log(error);
        } finally {
            await this.setState({
                ...this.state,
                loading: false
            });
        }


    }

    handleAplyColorRanges = async () => {
        let data = this.state.mapa_coropetico_current_showing.data;
        let colorRanges = this.state.color_ranges[this.state.mapa_coropetico_current_showing.type];
        let dadoRange = this.state.mapa_coropetico_current_showing.config.dado_range;

        data = {
            geojson: initializeColors(data.geojson, colorRanges, dadoRange)
        };

        this.showIbgeInfo(this.state.mapa_coropetico_current_showing.type, data);
    }

    getTransformedPayloadForSenso() {
        let polygons = [];
        let circles = this.state.circles;
        let polygonsOverlays = this.state.polygons;

        for (let i = 0; i < polygonsOverlays.length; i++) {
            let polygon = polygonsOverlays[i];

            let geojson = convertPolygonToGeojson(polygon)

            polygons.push({
                id: polygon.id,
                geojson: geojson
            })
        }


        for (let i = 0; i < circles.length; i++) {

            let circle = this.state.circles[i];

            if (circle.getMap() === null) {
                continue;
            }

            const center = [circle.getCenter().lng(), circle.getCenter().lat()];
            const radius = circle.getRadius();
            let polygonFromCircle = circleToPolygon(center, radius, 32);

            polygons.push({
                id: circles[i].id,
                geojson: polygonFromCircle
            });
        }

        let cidades = this.state.cidade;

        cidades = cidades.map((cidade) => {
            let cidadeParts = cidade.value.split('-');

            return {
                uf: cidadeParts[0],
                cidade: cidadeParts[1],
                id: cidade.value
            }

        });

        let retorno = { polygons, cidades };

        let hash = md5(JSON.stringify(retorno));

        retorno.hash = hash;

        console.log(retorno);


        return retorno;

    }

    setLoading = async (loading) => {
        await this.setState({
            ...this.state,
            loading: loading
        })
    }

    removeHeatMap = async () => {
        if (this.state.heatMap !== null) {
            this.state.heatMap.setMap(null);

            let empreendimentos = this.state.empreendimentos;

            for (let i = 0; i < empreendimentos.length; i++) {
                empreendimentos[i].show_marker = true;
                empreendimentos[i].customMarker = undefined;
            }

            await this.setState({
                ...this.state, showMarkers: true, heatMap: null, empreendimentos: [...empreendimentos]
            });
        }
    }

    //oculta ou mostra os kmls carregados
    kmlFunction = async () => {
        try {
            await this.setState({
                ...this.state,
                showKMl: !this.state.showKMl,
            });

        } catch (e) {
            console.log(e);
        }
    }

    //Exibe ou não os marcadores agrupados com ClusterMarker
    //https://tomchentw.github.io/react-google-maps/#markerclusterer
    toggleUseCluster = async () => {
        await this.setState({
            ...this.state,
            use_cluster: !this.state.use_cluster
        });
    }

    /**
     * Download de KML dos dados do Mapa
     * @returns {Promise<void>}
     */
    downloadKml = async () => {

        let payload = {
            ...this.state, polygons: [], circles: [], overlays: []
        }

        this.state.polygons.map((polygon) => {
            let polygonPath = getPolygonPath(polygon);
            payload.polygons.push(polygonPath);
        })

        payload.circles = await this.getCirclesPlainObjects();
console.log("fim getCirclesPlainObjects");
        await KmlService.download(payload);
    }

    /**
     * Salva dados do Mapa como Projeto
     * @returns {Promise<void>}
     */
    saveProject = async () => {
        try {
            await this.setState({
                ...this.state,
                loadingSaveProject: true
            });

            const payload = await this.createPayloadForProject();
            const response = await axios.post("/mapa-state/projeto", payload);

            await this.handleCloseProjectName();

            toast.success(response.data.message);

            await this.getProjects();
        } catch (error) {
            console.error("Erro ao salvar projeto:", error);

            if (error.response) {
                this.setState({
                    ...this.state,
                    errors_projeto: error.response.data.errors
                })
            }

        } finally {
            await this.setState({
                ...this.state,
                loadingSaveProject: false
            });
        }
    }

    /**
     * Monta o payload para salvar um projeto
     * @returns {Promise<Readonly<S>&{heatMap: null, heatMapLegendConfig: *[], polygons: *, overlays: *[], circles: unknown, heatMapConfig: {}}>}
     */
    createPayloadForProject = async () => {
        const polygonsPaths = this.state.polygons.map(polygon => getPolygonPath(polygon));
        const circles = await this.getCirclesPlainObjects();

        return {
            ...this.state,
            circles,
            heatMap: null,
            heatMapConfig: {},
            heatMapLegendConfig: [],
            polygons: polygonsPaths,
            overlays: [],
            share_users: this.state.project_share_users.map(user => user.id)
        };
    }


    /**
     * Busca os projetos salvos do usuário
     * @returns {Promise<void>}
     */
    getProjects = async () => {
        try {
            let projetos = [{ value: null, label: 'Selecione' }];
            const response = await axios.get(route('getMapaProjetos'));

            projetos = [...projetos, ...response.data.projects];

            await this.setState({
                ...this.state,
                projetos: projetos
            });
        } catch (error) {
            console.error("Erro ao carregar os nomes dos projetos", error);
        }
    }

    /**
     * Remove um projeto
     * @returns {Promise<void>}
     */
    deleteProject = async () => {

        try {
            let response = await axios.delete(route('mapa.project.delete', this.state.projeto));
            toast.success(response.data.message)

            await this.cleanState();
            await this.getProjects();
        } catch (error) {
            console.log(error);
        }

    }

    deleteWork = async () => {

        let projeto = [{ label: 'projeto', value: this.state.projeto }];

        axios.post(route('deleteMapaProjetoByName'), projeto).then(response => {
            alert(response.data.message)
        }).catch(error => {
            console.log("this is error", error);
        });
        await this.cleanState();
        await this.getProjects();

    }

    addPolygon = async (coordinate) => {

        let convertedCoordinates = [];

        coordinate.forEach(async (coord) => {
            convertedCoordinates.push({
                lat: coord[0], lng: coord[0],
            });
        });

        const polygon = new google.maps.Polygon({
            paths: convertedCoordinates,
            strokeColor: "#FF0000",
            strokeOpacity: 0.3,
            strokeWeight: 2,
            fillColor: this.getColor(),
            fillOpacity: 0.3,
        });

        polygon.type = 'Polígono';
        polygon.color = polygon.fillColor;
        polygon.id = this.generateRandomString();
        polygon.name = this.state.countPolygons + 1;

        polygon.setMap(window.googleMap.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED);
    }

    setHeatMapWeightType = async (type) => {
        await this.setState({
            ...this.state, heatMapWeightType: type
        });
    }


    addHeatMap = async (config) => {

        let weightType = config.type;

        if (this.state.heatMap) {
            this.state.heatMap.setMap(null);
        }

        await this.setState({
            ...this.state, heatMap: null, heatMapConfig: config
        })


        let map = window.googleMap.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;

        let heatMapaInfos = await this.getHeatMapWeights();

        let minIntensity = heatMapaInfos.minIntensity;
        let maxIntensity = heatMapaInfos.maxIntensity;
        let medianIntensity = 0;

        medianIntensity = parseInt(heatMapaInfos.maxIntensity / 2);

        let heatMap = new google.maps.visualization.HeatmapLayer({
            data: heatMapaInfos.heatMapData, dissipating: true, radius: 100, opacity: 0.4,
        });


        heatMap.setMap(map);


        this.setState({
            ...this.state,
            heatMap: heatMap,
            showMarkers: true,
            minIntensity: minIntensity,
            maxIntensity: maxIntensity,
            heatMapWeightType: weightType.value,
            medianIntensity: medianIntensity,
            heatMapConfig: config,
            heatMapLegendConfig: heatMapaInfos.heatMapLegendConfig,
            empreendimentos: [...this.state.empreendimentos],
            active_tab: 'legendas',
            left_side_bar_visible: true
        })
    }

    calculatePercentiles = async (field) => {

        if (field === 'oferta_inicial') {
            field = 'total_unidades';
        }

        let percentis = await percentile([12.5, 25, 32.5, 50, 62.5, 75, 87.5], this.state.empreendimentos, item => item[field]);

        return percentis.map((row) => row[field]);


    }


    getHeatMapWeights = async () => {
        return new Promise(async (resolve, reject) => {

            let percentis = await this.calculatePercentiles(this.state.heatMapConfig.type.value);

            let empreendimentos = this.state.empreendimentos;


            let weights = [];
            let heatMapData = [];

            let minIntensity = 0;
            let maxIntensity = 0;


            const heatMapLegendConfig = [];

            let colors = ['194D72', '3D8ABD', 'B1D974', '7CA83E', '5B7537', 'F1A30C', 'B29400', 'C50234']
            let previusPercentilIndex = 0;
            let increment = 1;

            if (!['oferta_final', 'oferta_inicial'].includes((this.state.heatMapConfig.type.value))) {
                increment = 0.1;
            }

            let min = percentis[0];
            percentis.forEach((percentile, index) => {


                if (index === 0) {
                    min = 0;
                } else {
                    min = percentis[previusPercentilIndex] + increment;
                }

                let max = percentile;

                if (['vgv_final', 'ticket_medio'].includes(this.state.heatMapConfig.type.value)) {
                    min = Number(min.toFixed(2)).toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                    max = Number(percentile.toFixed(2)).toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                }

                heatMapLegendConfig.push({
                    min: min,
                    max: max,
                    icon: `${process.env.MIX_APP_URL}/img/marcadores/mapa_de_calor/${colors[index]}.png`
                })

                previusPercentilIndex = index;
            });

            min = percentis[percentis.length - 1] + increment;
            if (['vgv_final', 'ticket_medio'].includes(this.state.heatMapConfig.type.value)) {
                min = Number(min.toFixed(2)).toLocaleString('pt-BR', { minimumFractionDigits: 2 });
            }


            heatMapLegendConfig.push({
                min: min,
                max: undefined,
                icon: `${process.env.MIX_APP_URL}/img/marcadores/mapa_de_calor/${colors[colors.length - 1]}.png`
            })


            for (let i = 0; i < empreendimentos.length; i++) {

                let empreendimento = empreendimentos[i];
                let weight = empreendimento[this.state.heatMapConfig.type.value];

                if (this.state.heatMapConfig.type.value === 'oferta_inicial') {
                    weight = parseInt(empreendimento["total_unidades"]);
                }

                empreendimento.show_marker = true;

                if (this.state.heatMapConfig.type.value === 'oferta_inicial') {
                    let dataLancamento = new Date(empreendimento.data_lancamento);

                    if (dataLancamento < this.state.heatMapConfig.data_inicial || dataLancamento > this.state.heatMapConfig.data_final) {
                        empreendimento.show_marker = false;
                    }


                }

                if (weight <= percentis[0]) {
                    empreendimento.customMarker = process.env.MIX_APP_URL + '/img/marcadores/mapa_de_calor/194D72.png'
                }

                if (weight > percentis[0] && weight <= percentis[1]) {
                    empreendimento.customMarker = process.env.MIX_APP_URL + '/img/marcadores/mapa_de_calor/3D8ABD.png'
                }

                if (weight > percentis[1] && weight <= percentis[2]) {
                    empreendimento.customMarker = process.env.MIX_APP_URL + '/img/marcadores/mapa_de_calor/B1D974.png'
                }

                if (weight > percentis[2] && weight <= percentis[3]) {
                    empreendimento.customMarker = process.env.MIX_APP_URL + '/img/marcadores/mapa_de_calor/7CA83E.png'
                }

                if (weight > percentis[3] && weight <= percentis[4]) {
                    empreendimento.customMarker = process.env.MIX_APP_URL + '/img/marcadores/mapa_de_calor/5B7537.png'
                }

                if (weight > percentis[4] && weight <= percentis[5]) {
                    empreendimento.customMarker = process.env.MIX_APP_URL + '/img/marcadores/mapa_de_calor/F1A30C.png'
                }

                if (weight > percentis[5] && weight <= percentis[6]) {
                    empreendimento.customMarker = process.env.MIX_APP_URL + '/img/marcadores/mapa_de_calor/B29400.png'
                }

                if (weight > percentis[6]) {
                    empreendimento.customMarker = process.env.MIX_APP_URL + '/img/marcadores/mapa_de_calor/C50234.png'
                }

                weights.push(weight);


                if (empreendimento.show_marker === true) {
                    heatMapData.push({
                        location: new google.maps.LatLng(empreendimento.location.coordinates[1], empreendimento.location.coordinates[0]),
                        weight: weight
                    });
                }

            }

            resolve({
                heatMapData: heatMapData,
                minIntensity: minIntensity,
                maxIntensity: maxIntensity,
                weights: weights,
                heatMapLegendConfig: heatMapLegendConfig
            });
        });
    }

    addCircle = async (circleProperties) => {
        console.log(circleProperties);

        const radius = circleProperties.radius;

        let newCircle = new google.maps.Circle({
            fillColor: circleProperties.fillColor,
            fillOpacity: 0.4,
            map: map,
            center: circleProperties.center,
            radius: radius
        });

        newCircle.id = circleProperties.id;
        newCircle.name = circleProperties.name;

        newCircle.type = circleProperties.type;
        newCircle.color = circleProperties.color;

        circle.setMap(window.googleMap.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED);

        await this.setState({
            ...this.state,
            drawingMode: null,
            circles: [...this.state.circles, newCircle],
            overlays: [...this.state.overlays, newCircle]
        });
    }

    getCirclesPlainObjects = async () => {
        console.log("inicio getCirclesPlainObjects");

        return new Promise((resolve, reject) => {
            let circles = [];

            for (let index = 0; index < this.state.circles.length; index++) {
                const circle = this.state.circles[index];

                console.log(circle);

                let newCircle = {
                    latitude: circle.getCenter().lat(),
                    longitude: circle.getCenter().lng(),
                    radius: circle.radius,
                    name: circle.name,
                    type: circle.type,
                }

                circles.push(newCircle);
            }

            resolve(circles);
        });
    }


    /**
     * Esconde o Painel de Informações do Empreendimento
     */
    handleHideClicked = (event) => {
        event.preventDefault();

        this.setState({
            ...this.state,
            panel_empreendimento_visible: false,
            modal_empreendimento_visible: false,
        });
    }

    togglePainelEmpreendimento = () => {
        this.setState({
            ...this.state,
            panel_empreendimento_visible: !this.state.panel_empreendimento_visible,
            modal_empreendimento_visible: !this.state.modal_empreendimento_visible,
            currentShowing: undefined
        });
        document.getElementById("btn-results").click();

    }

    componentDidMount = async () => {
        window.circles = [];
        window.markers = [];
        window.refs = [];
        let currentPosition = await getBrowserGeolocation();
        this.timer = null;

        this.setState({
            ...this.state, currentPosition: currentPosition,
        });
    }

    async componentDidUpdate(prevProps, prevState) {

        if (prevState.empreendimentos !== this.state.empreendimentos || prevState.use_cluster !== this.state.use_cluster || prevState.showMarkers !== this.state.showMarkers) {
            console.log('Reiniciando marcadores ');
            if (this.markers.length > 0) {
                this.markers.forEach(marker => {
                    marker.setMap(null);
                });
                this.markers = [];
            }

            if (this.state.empreendimentos.length > 0) {
                await this.initMarkers();

                if (this.state.use_cluster) {
                    this.markersClusterer = new MarkerClusterer({
                        markers: this.markers,
                        map: window.googleMap.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED,
                    });
                } else {

                    if (this.markersClusterer) {
                        this.markers.forEach(marker => {
                            this.markersClusterer.removeMarker(marker);
                        });
                        this.markersClusterer.setMap(null);
                    }

                }
            }
        }



    }


    getPadroes = () => {
        let padroesHorizontais = [{
            value: 'Condomínio de Casas/Sobrados',
            label: 'Condomínio de Casas/Sobrados'
        }, { value: 'Condomínio de Chácaras', label: 'Condomínio de Chácaras' }, {
            value: 'Loteamento Aberto',
            label: 'Loteamento Aberto'
        }, { value: 'Loteamento Fechado', label: 'Loteamento Fechado' },];

        let padroesVerticais = [{ value: 'Todos', label: 'Selecionar Todos' }, {
            value: 'Especial',
            label: 'Especial'
        }, { value: 'Econômico', label: 'Econômico' }, { value: 'Standard', label: 'Standard' }, {
            value: 'Médio',
            label: 'Médio'
        }, { value: 'Alto', label: 'Alto' }, { value: 'Luxo', label: 'Luxo' }, { value: 'Super Luxo', label: 'Super Luxo' },];

        if (this.state.tipo_empreendimento.includes('Horizontal')) {
            return [...padroesVerticais, ...padroesHorizontais]
        }

        return padroesVerticais;

    }


    onPolygonComplete = async (polygon, reloadEmpreendimentos = true) => {
        try {
            let path = getPolygonPath(polygon);

            //primeiro e último vértices devem ser iguais
            path.push(path[0]);

            polygon.type = 'Polígono';
            polygon.id = this.generateRandomString();
            polygon.path = path;

            //mapa isométrico utiliza somente cor para o contorno
            if (!polygon.color || polygon.color === 'transparent') {
                polygon.color = polygon.strokeColor;
            }

            if (!polygon.name) {
                polygon.name = this.state.countPolygons + 1;
            }

            google.maps.event.addListener(polygon, 'click', (event) => {
                this.editShapeName(polygon);
            });


            await this.setState({
                ...this.state,
                drawingMode: null,
                polygons: [polygon, ...this.state.polygons],
                overlays: [polygon, ...this.state.overlays],
                countPolygons: this.state.countPolygons + 1,
                left_side_bar_visible: false
            });

            this.fitBounds(await this.getPolygonBounds(polygon));

            await this.reloadEmpreendimentos();


        } catch (error) {
            console.log(error);
        }
    }

    cleanState = async () => {

        if (this.state.heatMap) {
            this.state.heatMap.setMap(null);
        }

        this.state.overlays.forEach((overlay) => {
            try {
                if (overlay.setMap !== undefined) {
                    overlay.setMap(null);
                }
            } catch (error) {
                console.log(error);
            }
        });

        this.state.kml_layers.forEach((kml_layer) => {
            try {
                if (kml_layer.setMap !== undefined) {
                    kml_layer.setMap(null);
                }
            } catch (error) {
                console.log(error);
            }
        });

        document.querySelector('#search_box').value = '';

        if (window.ibge_layer) {
            await this.removeIbgeDataLayer();
        }


        await this.setState({
            ...this.initialState, quartos: [], heatMap: null, showMarkers: true, use_cluster: false
        });

        await this.getProjects();


    }

    onRevendaMarkerClick = async (id) => {
        if ($("#card-results").hasClass("collapsed")) {
            $("#card-results").removeClass("collapsed");
            $("#btn-results").removeClass("btn-results-off");
            $(".side-panel").removeClass("side-panel-off");

            $(".sliding-panel-container").addClass("active");
            $("#results-icon").removeClass("fa-angle-double-left");
            $("#results-icon").addClass("fa-angle-double-right");
        }
        await this.setState({
            loading_empreendimento: true, // currentShowing: undefined,
        });

        let empreendimento = await EmpreendimentoServiceCrawler.getEmpreendimento(id.$oid);

        let selectedIcon = {
            position: {
                lat: empreendimento.location.coordinates[1], lng: empreendimento.location.coordinates[0],
            }, icon: "http://maps.google.com/mapfiles/kml/paddle/grn-stars.png",
        };

        await this.setState({
            currentShowing: empreendimento,
            panel_empreendimento_visible: true,
            modal_empreendimento_visible: true,
            selectedIcon: selectedIcon,
        });
        document.getElementById("btn-results").click();
        this.openPane();
    };

    onMarkerClick = async (empreendimento_selecionado) => {


        if ($("#card-results").hasClass('collapsed')) {
            $("#card-results").removeClass("collapsed");
            $("#btn-results").removeClass("btn-results-off");
            $(".side-panel").removeClass("side-panel-off");

            $('.sliding-panel-container').addClass('active');
            $("#results-icon").removeClass("fa-angle-double-left");
            $("#results-icon").addClass("fa-angle-double-right");

        }
        await this.setState({
            ...this.state, loading_empreendimento: true, currentShowing: undefined, loading: true
        });

        let empreendimento = await EmpreendimentoService.getEmpreendimento(empreendimento_selecionado._id);


        await this.setState({
            ...this.state,
            currentShowing: empreendimento,
            panel_empreendimento_visible: true,
            modal_empreendimento_visible: true,
            loading: false,
            left_side_bar_visible: false
        });


        document.getElementById("btn-results").click();
        return this.openPane();
    }


    generateRandomString = () => {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }

    onCircleComplete = async (circle) => {
        console.log("gerou o circulo dentro de onCircleComplete");

        circle.id = this.generateRandomString();
        circle.type = 'Círculo';
        circle.color = this.getColor();

        circle.name = this.state.circles.length + 1;

        google.maps.event.addListener(circle, 'radius_changed', (event) => {
            console.log(event);
            this.onCircleRadiusChanged(circle);
        });

        google.maps.event.addListener(circle, 'click', (event) => {
            this.editShapeName(circle);
        });

        await this.setState({
            ...this.state,
            drawingMode: null, //
            circles: [circle, ...this.state.circles],
            overlays: [circle, ...this.state.overlays],
            left_side_bar_visible: false

        });

        this.fitBounds(circle.getBounds());

        await this.reloadEmpreendimentos();

    }

    onRectangleComplete = async (rectangle) => {
        let bounds = rectangle.getBounds();
        let northEast = bounds.getNorthEast();
        let southWest = bounds.getSouthWest();

        let northWest = [northEast.lat(), southWest.lng()];
        let southEast = [southWest.lat(), northEast.lng()];

        let path = [[northEast.lat(), northEast.lng()], [northWest[0], northWest[1]], [southWest.lat(), southWest.lng()], [southEast[0], southEast[1]], [northEast.lat(), northEast.lng()],];

        rectangle.id = 'retangulo-' + this.generateRandomString();
        rectangle.name = this.state.countRectangles + 1;

        rectangle.type = 'Retângulo';
        rectangle.path = path;

        google.maps.event.addListener(rectangle, 'click', (event) => {

            this.editShapeName(rectangle);
        });

        await this.setState({
            ...this.state, //empreendimentos: empreendimentos,
            drawingMode: null,
            polygons: [rectangle, ...this.state.polygons],
            overlays: [rectangle, ...this.state.overlays],
            countRectangles: this.state.countRectangles + 1,
            left_side_bar_visible: false
        });

        this.fitBounds(bounds);

        await this.reloadEmpreendimentos();

    }

    openPane = () => {
        this.setState({
            ...this.state,
            isSlideOpen: true,
        });
    }


    handleAnoLancamentoChanged = async (e) => {

        if (e === null) {

            await this.setState({
                ...this.state, ano_lancamento: [],
            });

            return;
        }

        let ano_lancamento = [];

        e.forEach((ano) => {

            if (!ano_lancamento.includes(ano)) {
                ano_lancamento.push(ano);
            }
        })

        this.setState({
            ...this.state, ano_lancamento: ano_lancamento
        });

    }
    handleMesAnoLancamentoChanged = async (e) => {

        await this.setState({
            ...this.state, mes_ano_lancamento: e
        });

    }

    handleEstagioChanged = async (e) => {

        if (e === null) {

            await this.setState({
                ...this.state, estagio: [],
            });

            return;
        }

        let estagio = [];

        e.forEach((ano) => {

            if (!estagio.includes(ano)) {
                estagio.push(ano);
            }
        })

        this.setState({
            ...this.state, estagio: estagio
        });
    }

    closePane = () => {
        this.setState({
            isSlideOpen: false
        });
    }

    handleTipoEmpreendimentoChanged = async (e) => {

        let tipos = this.state.tipo_empreendimento;

        if (!tipos.includes(e.target.value)) {
            await this.setState({
                tipo_empreendimento: [...tipos, e.target.value]
            });
        } else {
            tipos.splice(tipos.indexOf(e.target.value), 1);

            await this.setState({
                tipo_empreendimento: tipos
            });
        }

        //this.reloadEmpreendimentos();
    }

    handleIncorporadoraChanged = async (e) => {
        if (e === null) {
            await this.setState({
                ...this.state, incorporadoras: []
            });

            return;
        }

        let incorporadoras = [];
        e.forEach((option) => {
            if (!incorporadoras.includes(option)) {
                incorporadoras = [...incorporadoras, option];
            }

        });

        await this.setState({
            incorporadoras: [...incorporadoras], movimentar_mapa: true
        });

    }


    handleNomeEmpreendimentoChanged = async (e) => {
        if (e == null) {
            await this.setState({
                nome_empreendimento: []
            });

            return;
        }

        let nome_empreendimento = [];
        e.forEach((option) => {
            if (!nome_empreendimento.includes(option)) {
                nome_empreendimento = [...nome_empreendimento, option];
            }
        });

        //console.log(incorporadoras);
        await this.setState({
            nome_empreendimento: [...nome_empreendimento], movimentar_mapa: true
        });

    }

    searchEmpreendimentos = async (inputQuery) => {
        if (inputQuery.length > 1) {
            try {
                let endpoint = '/empreeendimentos-options?nome=' + inputQuery;

                if (this.state.cidade !== '' && this.state.cidade.length > 0) {

                    //join cidades label
                    let cidades = '';

                    this.state.cidade.forEach((cidade) => {
                        cidades += cidade.label + ',';
                    });

                    endpoint += `&cidade=${cidades.trim().slice(0, -1)}`;


                }

                if (this.state.estado.length > 0) {

                    let estados = '';

                    this.state.estado.forEach((estado) => {
                        estados += estado + ',';
                    });

                    endpoint += `&estado=${estados.trim().slice(0, -1)}`;
                }

                let empreendimentos = await axios.get(endpoint);

                return empreendimentos.data;
            } catch (error) {
                console.log(error);
            }
        }
    }


    reloadEmpreendimentosWithoutMetaData = async () => {
        //Caso não tenha formas desenhadas no mapa a busca será por cidade
        if (this.thereAreDrownShapes() === false) {
            if (this.state.overlays.length === 0 && this.state.cidade.length === 0 && this.state.estado.length === 0) {
                alert('Desenhe uma área no Mapa ou digite uma Cidade na caixa de busca do mapa');
                return;
            }

            return await this.reloadEmpreendimentosOnCidadeWithouMetaData()
        }

        return await this.reloadEmpreendimentosWithouMetaData();
    }


    reloadEmpreendimentos = async () => {

        if (this.thereAreDrownShapes() === false) {
            if (this.state.overlays.length === 0 && this.state.cidade.length === 0 && this.state.estado.length === 0) {
                toast.error("Desenhe uma área no Mapa ou digite uma Cidade na caixa de busca do mapa");
                return;
            }
        }

        try {

            window.ibge_layer = null;

            if (this.state.heatMap) {
                this.state.heatMap.setMap(null);
            }

            if (this.state.ibgeDataLayer) {
                this.state.ibgeDataLayer.setMap(null);
            }

            await this.setState({
                ...this.state,
                loading: true,
                loadingMetadata: true,
                empreendimentos: [],
                polos_geradores: [],
                heatMap: null,
                selectedIcon: undefined,

            });

            await this.reloadEmpreendimentosWithoutMetaData();

            if (this.thereAreDrownShapes() === false) {
                if (this.state.overlays.length === 0 && this.state.cidade.length === 0 && this.state.estado.length === 0) {
                    alert('Desenhe uma área no Mapa ou digite uma Cidade na caixa de busca do mapa');
                    return;
                }

                return this.reloadEmpreendimentosOnCidade();
            }

            let empreendimentos = [];
            let polosGeradores = [];
            let revendaLocacao = [];

            const { empreendimentos: empreendimentosInCircles, polos_geradores: polosGeradoresOnCircle } = await this.reloadEmpreendimentosOnCircle();

            const { empreendimentos: empreendimentosOnPolygons, polos_geradores: polosGeradoresOnPolygons } = await this.reloadEmpreendimentosOnPolygons();

            empreendimentos = [...empreendimentosInCircles, ...empreendimentosOnPolygons];
            polosGeradores = [...polosGeradoresOnCircle, ...polosGeradoresOnPolygons];

            if (this.state.showResaleOrLocationMarkers) {
                const { empreendimentos: revendaPolygons } = await this.reloadRevendaEmpreendimentosOnPolygons();
                const { empreendimentos: revendaCircle } = await this.reloadRevendaEmpreendimentosOnCircle();
                revendaLocacao = [...revendaCircle, ...revendaPolygons];
            }

            //retira os empreendimentos duplicados
            let empreedimentosWithoutDuplicate = await this.removeDuplicates(empreendimentos);

            this.mapBuilding(empreedimentosWithoutDuplicate);

            let isSlideOpen = empreedimentosWithoutDuplicate.length > 0;

            await this.setState({
                ...this.state,
                empreendimentos: empreedimentosWithoutDuplicate,
                polos_geradores: polosGeradores,
                revendaLocacao: revendaLocacao,
                isSlideOpen: isSlideOpen
            });

            if (this.state.ranges.length > 0) {
                await this.replaceMarkers();
            }

            if (this.state.revendaLocacao.length > 0) {
                await this.replaceRevendaMarkers()
            }

            await this.fitMap();

            if (empreedimentosWithoutDuplicate.length > 0) {

                if (this.state.showRenda) {
                    await this.loadMapaCloropetico(this.state.mapa_coropetico_current_showing?.type);
                }

                if (Object.keys(this.state.heatMapConfig).length > 0) {
                    await this.addHeatMap(this.state.heatMapConfig);
                }

            } else {
                toast.warn('Nenhum resultado com os parametros fornecidos')
            }

        } catch (e) {
            console.log(e);
        } finally {
            await this.setState({
                ...this.state,
                loading: false,
            });
        }
    }

    fitMap = async () => {
        if (this.state.movimentar_mapa) {
            try {
                let bounds = new google.maps.LatLngBounds();

                this.state.empreendimentos.forEach((empreendimento) => {
                    let coords = { lng: empreendimento.location.coordinates[0], lat: empreendimento.location.coordinates[1] }
                    bounds.extend(coords);
                });

                this.fitBounds(bounds);
            } catch (error) {
                console.log(error)
            }
        }
    }

    handlePadraoChanged = async (e) => {
        if (e === null) {
            await this.setState({
                ...this.state, padrao: [],
            });
            return;
        }

        let padroes = [];
        e.forEach((padrao) => {
            if (padrao.value === 'Todos') {
                padroes = [
                    { value: 'Especial', label: 'Especial' },
                    { value: 'Econômico', label: 'Econômico' },
                    { value: 'Standard', label: 'Standard' },
                    { value: 'Médio', label: 'Médio' },
                    { value: 'Alto', label: 'Alto' },
                    { value: 'Luxo', label: 'Luxo' },
                    { value: 'Super Luxo', label: 'Super Luxo' },
                ];

                return;
            }

            if (!padroes.includes(padrao)) {
                padroes.push(padrao)
            }
        })

        await this.setState({
            ...this.state, padrao: padroes,
        });
    }

    handleProjetoChanged = async (e) => {
        await this.cleanState();
        await this.getProjects();

        const projetoResponse = await axios.get(route('mapa.project.show', e.value));
        const projetoData = projetoResponse.data.project;

        let ponto = {
            lat: parseFloat(projetoData.lat),
            lng: parseFloat(projetoData.lng)
        };

        let cidade = JSON.parse(projetoData.cidade) || [];
        let estado = JSON.parse(projetoData.estado) || [];
        let bairros = JSON.parse(projetoData.bairros) || [];
        let estagio = JSON.parse(projetoData.estagio) || [];
        let nome_empreendimentos = JSON.parse(projetoData.nome_empreendimento)?.map((empreendimento) => {
            return { label: empreendimento, value: empreendimento };
        }) || [];

        let parseProjectData = await this.parseProjectData(projetoData);

        let mes_ano_lancamento = projetoData.mes_ano_lancamento != null ? new Date(projetoData.mes_ano_lancamento) : '';


        const updateState = {
            projeto: e.value,
            movimentar_mapa: true,
            polos_geradores: [],
            show_polos_geradores: false,
            overlay_cidade: true,
            cidade: cidade,
            estado: estado,
            bairros: bairros,
            pontos: [ponto],
            ...parseProjectData,
            nome_empreendimento: [...nome_empreendimentos],
            mes_ano_lancamento: mes_ano_lancamento,
            estagio: estagio
        };

        await this.setState(updateState);

        if (projetoData?.polignos != null) {
            const polygons = JSON.parse(projetoData.polignos);
            polygons.forEach(polygon => this.createAndAddPolygon(polygon));
        }

        if (projetoData?.manualCircles != null) {
            const manualCircles = JSON.parse(projetoData.manualCircles);

            console.log(manualCircles);

            for (let i = 0; i < manualCircles.length; i++) {
                let circle = manualCircles[i];

                let color = circle.color || this.getColor();

                let center = { lat: parseFloat(circle?.latitude), lng: parseFloat(circle?.longitude) };

                if (circle.center) {

                    center = {
                        lat: parseFloat(circle.center.lat),
                        lng: parseFloat(circle.center.lng)
                    };

                }

                let newCircle = new google.maps.Circle({
                    ...defaultCircleOptions,
                    center: center,
                    radius: circle.radius,
                    name: circle.name,
                    type: circle.type || 'Círculo',
                    color: color,
                    fillColor: color,
                    id: circle.id,
                });

                newCircle.setMap(window.googleMap.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED);

                await this.onCircleComplete(newCircle);
            }
        }

        await this.reloadEmpreendimentos();
    }

    parseProjectData = async (projetoData) => {
        const fields = [
            'tipo_empreendimento', 'quartos', 'padrao', 'status',
            'incorporadoras', 'address', 'ano_lancamento',
            'area_maior_que', 'area_menor_que', 'valor_maior_que', 'valor_menor_que',
            'valor_metro_maior_que', 'valor_metro_menor_que', 'juros_minimo', 'juros_maximo',
            'valor_parcela_minima', 'valor_parcela_maxima', 'parcelas_minimo', 'parcelas_maximo',
            'raio', 'ranges', 'porcentagem_vendida_minima', 'porcentagem_vendida_maxima',
        ];

        const stateUpdate = fields.reduce((acc, field) => {
            if (projetoData?.[field] != null) {
                acc[field] = typeof projetoData[field] === 'string' ? JSON.parse(projetoData[field]) : projetoData[field];
            }
            return acc;
        }, {});
        return stateUpdate;
    }

    createAndAddPolygon = async (polygonData, aditionalOptions = {}) => {

        const coordinates = polygonData.map(coordinate => ({ lat: coordinate[0], lng: coordinate[1] }));
        const mapPolygon = new google.maps.Polygon({
            ...defaultPolygonOptions,
            paths: coordinates,
            fillColor: this.getColor(),
            ...aditionalOptions
        });

        mapPolygon.setMap(window.googleMap.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED);
        await this.onPolygonComplete(mapPolygon);
    }

    handleAreaMinimaChanged = async (e) => {
        await this.setState({
            area_maior_que: parseFloat(e.target.value)
        });
    }

    /**
     * Altera o valor da porcentagem vendida minima
     * @param e
     * @returns {Promise<void>}
     */
    handlePorcentagemVendidaMinimaChanged = async (e) => {
        await this.setState({
            porcentagem_vendida_minima: parseFloat(e.target.value)
        });
    }

    /**
     * Altera o valor da porcentagem vendida maxima
     * @param e
     * @returns {Promise<void>}
     */
    handlePorcentagemVendidaMaximaChanged = async (e) => {
        await this.setState({
            porcentagem_vendida_maxima: parseFloat(e.target.value)
        });
    }


    handleRaioChanged = async (e) => {
        window.circles.forEach((circle) => {
            circle.setRadius(parseInt(e.target.value))
        });
        await this.setState({
            raio: parseInt(e.target.value)
        });
    }

    handleAreaMaximaChanged = async (e) => {
        await this.setState({
            area_menor_que: parseFloat(e.target.value)
        });
    }

    handleStatusChanged = async (e) => {

        if (e === null) {
            await this.setState({
                ...this.state, status: [],

            });

            return;
        }

        let status = [];
        e.forEach((statusSelected) => {
            if (statusSelected.value === 'Todos') {
                status = [
                    { value: 'Comercialização', label: 'Comercialização' },
                    { value: 'Esgotado', label: 'Esgotado' },
                    { value: 'Futuro', label: 'Futuro' },
                    { value: 'Suspenso', label: 'Suspenso' },];

                return;
            }

            if (!status.includes(statusSelected)) {
                status.push(statusSelected);
            }
        })

        await this.setState({
            status: status
        });
    }

    handleTipoChanged = async (e) => {
        if (e === null) {
            await this.setState({
                ...this.state, tipo_negocio: [],
            });

            return;
        }

        let tipo_negocio = [];
        e.forEach((tipoSelected) => {
            if (tipoSelected.value === "Todos") {
                tipo_negocio = [{ value: "Venda", label: "Revenda" }, { value: "Aluguel", label: "Locação" },];
                return;
            }

            if (!tipo_negocio.includes(tipoSelected)) {
                tipo_negocio.push(tipoSelected);
            }
        });

        await this.setState({
            tipo_negocio: tipo_negocio,
        });
    };

    handleBairrosChanged = async (e) => {

        if (e === null) {
            await this.setState({
                ...this.state, bairros: [],
            });

            return;
        }

        let bairros = [];
        e.forEach((bairroSelected) => {

            if (!status.includes(bairroSelected)) {
                bairros.push(bairroSelected);
            }
        })

        await this.setState({
            bairros: bairros, movimentar_mapa: true
        });
    }

    handleValorMinimoChanged = async (value) => {
        await this.setState({
            valor_maior_que: value
        });
    }

    handleValorMaximoChanged = async (value) => {
        await this.setState({
            valor_menor_que: value
        });
    }

    handleValorMetroMinimoChanged = async (value) => {
        await this.setState({
            valor_metro_maior_que: value
        });
    }

    handleJurosMinimoChanged = async (e) => {
        this.setState({
            juros_minimo: Number(e.target.value)
        });
    }

    handleJurosMaximoChanged = async (e) => {
        this.setState({
            juros_maximo: Number(e.target.value)
        });
    }

    handleParcelasMinimoChanged = async (e) => {
        this.setState({
            parcelas_minimo: Number(e.target.value)
        });
    }

    handleParcelasMaximoChanged = async (e) => {
        this.setState({
            parcelas_maximo: Number(e.target.value)
        });
    }

    handleValorMetroMaximoChanged = async (value) => {

        this.setState({
            valor_metro_menor_que: value
        });
    }

    handleValorParcelaMaximaChanged = async (value) => {

        this.setState({
            valor_parcela_maxima: value
        });
    }

    handleValorParcelaMinimaChanged = async (value) => {

        this.setState({
            valor_parcela_minima: value
        });
    }

    handleNameProjectChanged = async (e) => {
        this.setState({
            project_name: e.target.value
        });
    }

    handleQuartosChanged = async (e) => {
        const value = e.target.value;

        let quartos = this.state.quartos;

        if (e.target.checked && !quartos.includes(value)) {
            quartos.push(value);
        }

        if (!e.target.checked && quartos.includes(value)) {
            var index = quartos.indexOf(value);

            if (index > -1) {
                quartos.splice(index, 1);
            }
        }

        await this.setState({
            ...this.state, quartos: quartos
        });

        //this.reloadEmpreendimentos();
    }

    handleVagasChanged = async (e) => {
        const value = parseInt(e.target.value);

        let vagas = this.state.vagas;

        if (e.target.checked && !vagas.includes(value)) {
            vagas.push(value);
        }

        if (!e.target.checked && vagas.includes(value)) {
            var index = vagas.indexOf(value);

            if (index > -1) {
                vagas.splice(index, 1);
            }
        }

        await this.setState({
            ...this.state, vagas: vagas
        });

        //this.reloadEmpreendimentos();
    }

    removeDuplicates = async (empreendimentos) => {

        return new Promise((resolve, reject) => {
            let empreendimentosSet = new Set();

            let empreendimentosWithoutDuplicate = empreendimentos.filter((empreendimento) => {
                const duplicate = empreendimentosSet.has(empreendimento._id);
                empreendimentosSet.add(empreendimento._id);
                return !duplicate;
            });

            resolve(empreendimentosWithoutDuplicate);
        });
    }

    reloadRevendaEmpreendimentosOnCircle = async () => {
        var empreendimentos = [];

        if (this.state.circles.length > 0) {
            for (let x = 0; x < this.state.circles.length; x++) {
                let circle = this.state.circles[x];

                if (circle.getMap() === null) {
                    continue;
                }

                const center = circle.getCenter();
                const radius = circle.getRadius();
                const color = circle.color;

                let empreendimentosResponse = await EmpreendimentoServiceCrawler.getEmpreendimentosWithinRadius(center.lat(), center.lng(), radius, this.state);
                circle.excel = empreendimentosResponse.data.excel;
                circle.count_buildings = empreendimentosResponse.data.count_buildings;
                circle.count_buildings_venda = empreendimentosResponse.data.count_buildings_venda;
                circle.count_buildings_aluguel = empreendimentosResponse.data.count_buildings_aluguel;
                circle.info_venda = empreendimentosResponse.data.info_venda;
                circle.info_aluguel = empreendimentosResponse.data.info_aluguel;

                let empreendimentosFromResponse = empreendimentosResponse.data.empreendimentos;

                empreendimentosFromResponse.map((empreendimento) => {
                    empreendimento.color = color;
                });

                let empreendimentosWithoutDuplicates = await this.removeDuplicates([...this.state.empreendimentos, ...empreendimentosFromResponse,]);

                empreendimentos = [...empreendimentos, ...empreendimentosWithoutDuplicates,];
            }
        }

        return { empreendimentos };
    }; //fim reloadEmpreendimentosOnCircle

    reloadEmpreendimentosOnCircle = async () => {

        console.log('Chegou Aqui');

        var empreendimentos = [];
        var polos_geradores = [];

        if (this.state.circles.length > 0) {
            for (let x = 0; x < this.state.circles.length; x++) {
                let circle = this.state.circles[x];

                if (circle.getMap() === null) {
                    continue;
                }

                const center = circle.getCenter();
                const radius = circle.getRadius();
                const color = circle.color;

                let empreendimentosResponse = await EmpreendimentoService.getEmpreendimentosWithinRadius(center.lat(), center.lng(), radius, this.state);

                circle.meta_data = empreendimentosResponse.data.meta_data;
                circle.cache_key = empreendimentosResponse.data.meta_data.cache_key;

                if (this.state.show_polos_geradores) {

                    let polosGeradoresResponse = await PolosGeradoresService.getPolosGeradoresWithinRadius(center.lat(), center.lng(), radius, this.getPolosGeradoresTypesAsArray());
                    circle.polos_geradores = polosGeradoresResponse.polos_geradores;
                    polos_geradores = [...polosGeradoresResponse.polos_geradores];
                    circle.polos_geradores_cache_key = polosGeradoresResponse.cache_key;

                }

                let empreendimentosFromResponse = empreendimentosResponse.data.empreendimentos;

                empreendimentosFromResponse.map((empreendimento) => {
                    empreendimento.color = color;
                });

                let empreendimentosWithoutDuplicates = await this.removeDuplicates([...this.state.empreendimentos, ...empreendimentosFromResponse]);

                empreendimentos = [...empreendimentos, ...empreendimentosWithoutDuplicates];
            }
        }

        return { empreendimentos, polos_geradores };
    }//fim reloadEmpreendimentosOnCircle


    reloadRevendaEmpreendimentosOnPolygons = async () => {
        let empreendimentos = [];

        if (this.state.polygons.length > 0) {
            for (let x = 0; x < this.state.polygons.length; x++) {
                let polygon = this.state.polygons[x];

                if (polygon.getMap() === null) {
                    continue;
                }

                let path = getPolygonPath(polygon);
                path.push(path[0]);

                let empreendimentosResponse = await EmpreendimentoServiceCrawler.getEmpreendimentosWithinPolygon(path, this.state);

                polygon.excel = empreendimentosResponse.data.excel;
                polygon.count_buildings = empreendimentosResponse.data.count_buildings;
                polygon.count_buildings_venda = empreendimentosResponse.data.count_buildings_venda;
                polygon.count_buildings_aluguel = empreendimentosResponse.data.count_buildings_aluguel;
                polygon.info_venda = empreendimentosResponse.data.info_venda;
                polygon.info_aluguel = empreendimentosResponse.data.info_aluguel;

                let empreendimentosFromResponse = empreendimentosResponse.data.empreendimentos;

                empreendimentosFromResponse.map((empreendimento) => {
                    empreendimento.color = polygon.color;
                });

                empreendimentos = [...empreendimentos, ...empreendimentosResponse.data.empreendimentos,];
            }
        }

        return { empreendimentos };
    };

    //Recarregar empreendimentos com polignos
    reloadEmpreendimentosOnPolygons = async () => {
        let empreendimentos = [];
        let polos_geradores = [];

        if (this.state.polygons.length > 0) {
            for (let x = 0; x < this.state.polygons.length; x++) {
                let polygon = this.state.polygons[x];

                if (polygon.getMap() === null) {
                    continue;
                }

                let path = getPolygonPath(polygon);
                path.push(path[0]);


                let empreendimentosResponse = await EmpreendimentoService.getEmpreendimentosWithinPolygon(path, this.state);

                polygon.meta_data = empreendimentosResponse.data.meta_data;
                if (polygon.fillColor === 'transparent') {
                    polygon.color = polygon.strokeColor;
                } else {
                    polygon.color = polygon.fillColor;
                }
                let empreendimentosFromResponse = empreendimentosResponse.data.empreendimentos;


                empreendimentosFromResponse.map((empreendimento) => {
                    empreendimento.color = polygon.color;
                });

                let polosGeradoresResponse = [];
                if (this.state.show_polos_geradores) {

                    polosGeradoresResponse = await PolosGeradoresService.getPolosGeradoresWithinPolygon(path, this.getPolosGeradoresTypesAsArray());
                    polos_geradores = [...polos_geradores, ...polosGeradoresResponse.polos_geradores];
                    polygon.polos_geradores_cache_key = polosGeradoresResponse.cache_key;

                }

                polygon.polos_geradores = polosGeradoresResponse.polos_geradores;


                empreendimentos = [...empreendimentos, ...empreendimentosResponse.data.empreendimentos];

            }
        }

        return { empreendimentos, polos_geradores: polos_geradores };
    }


    reloadEmpreendimentosOnCidade = async () => {

        let overlay = {};

        let empreendimentosResponse = await EmpreendimentoService.getEmpreendimentoOnCidade(this.state);

        overlay.meta_data = empreendimentosResponse.data.meta_data;
        overlay.type = 'cidade';
        overlay.id = 'cidades';
        overlay.color = this.getColor();


        let empreendimentosFromResponse = empreendimentosResponse.data.empreendimentos;

        this.mapBuilding(empreendimentosFromResponse);


        let polos_geradores = [];

        if (this.state.show_polos_geradores) {
            let polosGeradoresResponse = await PolosGeradoresService.getPolosGeradoresFromCity(this.state.cidade, this.getPolosGeradoresTypesAsArray());

            overlay.polos_geradores = polosGeradoresResponse.polos_geradores;
            overlay.polos_geradores_cache_key = polosGeradoresResponse.cache_key;

            polos_geradores = [...polosGeradoresResponse.polos_geradores];
        }

        let revendaLocacao = [];
        if (this.state.showResaleOrLocationMarkers) {

            let revendaResponse = await EmpreendimentoServiceCrawler.getEmpreendimentoOnCidade(this.state)

            revendaLocacao = revendaResponse.data.empreendimentos;
            overlay.excel = revendaResponse.data.excel;
            overlay.count_buildings = revendaResponse.data.count_buildings;
            overlay.count_buildings_venda = revendaResponse.data.count_buildings_venda;
            overlay.count_buildings_aluguel = revendaResponse.data.count_buildings_aluguel;
            overlay.info_venda = revendaResponse.data.info_venda;
            overlay.info_aluguel = revendaResponse.data.info_aluguel;


        }

        await this.setState({
            ...this.state,
            empreendimentos: empreendimentosFromResponse,
            overlays: [overlay, ...this.state.overlays],
            polos_geradores: [...polos_geradores],
            revendaLocacao: revendaLocacao,
            overlay_cidade: true,
            loadingMetadata: false
        });

        if (this.state.kml_bairros.length > 0) {

            await this.setState({
                ...this.state, showKMl: false
            });

            //toggle
            if (this.state.kmls_to_show.length > 0) {
                this.cleanKmlLayers();
            }

            await this.loadKml();
        }

        if (this.state.revendaLocacao.length > 0) {
            await this.replaceRevendaMarkers()
        }

        if (this.state.empreendimentos.length > 0) {
            if (this.state.ranges.length > 0) {
                await this.replaceMarkers();
            }

            if (Object.keys(this.state.heatMapConfig).length > 0) {
                await this.addHeatMap(this.state.heatMapConfig);
            }


            await this.fitMap();
            this.openPane();


            if (this.state.showRenda) {
                await this.showIbgeInfo();
            }


        } else {
            console.log('sem resultados');
            toast.warn('Nenhum resultado com os parametros fornecidos na cidade')
        }

    }

    reloadEmpreendimentosOnCidadeWithouMetaData = async () => {

        let response = await SimpleEmpreendimentosService.getEmpreendimentoOnCidade(this.state);

        await this.setState({
            empreendimentos: [...response.data],
            overlay_cidade: true,
            loading: false,
            loadingMetadata: true,
            isSlideOpen: response.data.length > 0,
        });

    }

    initMarkers = async () => {

        if (!this.state.showMarkers) {
            if (this.marker.length > 0) {
                this.marker.forEach((marker) => {
                    marker.setMap(null);
                });
            }

            return;
        }

        let map = window.googleMap.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;

        for (let i = 0; i < this.state.empreendimentos.length; i++) {
            let empreendimento = this.state.empreendimentos[i];

            if (empreendimento.show_marker) {


                const glyphImg = document.createElement("img");

                let icon = empreendimento.marker;

                let pinElement = null;

                if (empreendimento.customMarker) {

                    if (typeof empreendimento.customMarker === 'string') {
                        icon = empreendimento.customMarker;
                    }

                    if (typeof empreendimento.customMarker === 'object') {
                        pinElement = new google.maps.marker.PinElement({
                            background: empreendimento.customMarker.background,
                            borderColor: empreendimento.customMarker.borderColor,
                            glyphColor: empreendimento.customMarker.glyphColor,
                            scale: empreendimento.customMarker?.scale || 1,
                        });
                    }

                }

                glyphImg.src = icon;

                let position = {
                    lat: empreendimento.location.coordinates[1],
                    lng: empreendimento.location.coordinates[0]
                };

                let content = glyphImg;

                if (pinElement) {
                    content = pinElement.element;
                }

                let marker = new google.maps.marker.AdvancedMarkerElement({
                    map: map,
                    position: position,
                    content: content,
                    title: empreendimento.nome,
                    gmpClickable: true
                });

                //show infoWindow on mouseover
                marker.addEventListener('mouseover', (e) => {
                    console.log("Mouse over");
                    this.empreendimentoInfoWindow(empreendimento, marker);
                });

                marker.addEventListener('mouseleave', (e) => {
                    this.infoWindow.close();
                });

                marker.addEventListener('gmp-click', (e) => {
                    this.onMarkerClick(empreendimento);
                });

                this.markers.push(marker);
            }
        }
    }


    empreendimentoInfoWindow = (empreendimento, marker) => {

        if (this.infoWindow) {
            this.infoWindow.close();

            this.infoWindow = null;
        }

        let htmlContent = `<div>
                            <h5>${empreendimento.nome}</h5>
                            <small>
                                <strong>Endereço: </strong>${empreendimento.endereco} ${empreendimento.numero} <br />
                                <strong>Bairro: </strong>${empreendimento.bairro}
                            </small>
                        </div>`;

        let position = { lat: empreendimento.location.coordinates[1], lng: empreendimento.location.coordinates[0] };

        this.infoWindow = new google.maps.InfoWindow({
            content: htmlContent,
            position: position,
        });

        this.infoWindow.open({
            anchor: marker,
            map: window.googleMap.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED,
        });


    }





    reloadEmpreendimentosWithouMetaData = async () => {

        let response = await ReloadBuildingsWithoutMetaData.reloadBuildingsWithoutMetaData(this.state);

        let empreedimentosWithoutDuplicate = await this.removeDuplicates(response);

        let isSlideOpen = empreedimentosWithoutDuplicate.length > 0;

        await this.setState({
            empreendimentos: empreedimentosWithoutDuplicate,
            loading: false,
            loadingMetadata: false,
            isSlideOpen: isSlideOpen
        })

        if (this.state.ranges.length > 0) {
            await this.replaceMarkers();
        }

        if (empreedimentosWithoutDuplicate.length === 0) {
            toast.warn('Nenhum resultado com os parametros fornecidos')
        }
    }

    setLocation = async (place) => {
        try {

            await this.setState({
                ...this.state, loading: true
            });

            let latitude = place[0].geometry.location.lat();
            let longitude = place[0].geometry.location.lng();

            //adiciona um Marca no Ponto da Busca
            let ponto = {
                lat: latitude, lng: longitude
            };

            const reverse = await geolocationService.reverse(latitude, longitude, place);

            await this.getKmlBairros(reverse.cidade, reverse.uf);

            let cidadeValue = {
                label: reverse.cidade, value: `${reverse.uf}-${reverse.cidade}`
            }


            let bairros = await this.fetchBairros(reverse.cidade);
            let newBairrosOptions = [...this.state.bairrosOptions, ...bairros];

            await this.setState({
                ...this.state,
                cidade: [cidadeValue, ...this.state.cidade],
                estado: [reverse.uf, ...this.state.estado],
                bairrosOptions: newBairrosOptions,
                overlay_cidade: true,
                pontos: [...this.state.pontos, ponto]
            })

            await this.reloadEmpreendimentos();
            document.querySelector('#search_box').value = '';

        } catch (error) {
            console.log('erro ao buscar cidade');
            console.log(error);
        } finally {
            await this.setState({
                ...this.state, loading: false
            });
        }


    }

    handleEstadosChanged = async (event, status) => {

        if (status === true || event == null) {
            await this.setState({
                ...this.state, estado: [], cidade: [], estadosSelecionados: null
            });
            return
        }

        let estados = event.map((index) => {
            return index.value;
        });

        await this.setState({
            ...this.state, estado: estados, cidade: [], estadosSelecionados: event
        });

        try {
            //na busca pelo estado centraliza o mapa na capital do estado, para isso fazemos a busca dos dados da capital
            let response = await axios.get(route('state.capital', { state: event[0].value }));

            if (response.status === 200) {
                let coordinates = {
                    lat: response.data.lat, lng: response.data.lng
                }

                window.googleMap.panTo(coordinates);
                window.googleMap.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setZoom(12);
            }
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Handle Cidade Changed pelo campo do filtro onde os resultados não tem latitude e longitude
     */
    handleCidadeChangedOnFormFilter = async (cidadeOption) => {


        if (cidadeOption === null) {
            this.setState({
                ...this.state, cidade: [], estado: [], newBairrosOptions: []
            });

            return;
        }

        let estados = [];
        let cidades = [];
        let newBairrosOptions = [];

        for (let x = 0; x < cidadeOption.length; x++) {
            let option = cidadeOption[x];
            //alert( option );
            const cidadeParts = option.value.split('-');
            let estado = cidadeParts[0].trim();

            if (!cidades.includes(option)) {
                cidades.push(option);
            }

            if (!estados.includes(estado)) {
                estados.push(estado);
            }

            let bairros = await this.fetchBairros(option.label);


            newBairrosOptions = [...bairros, newBairrosOptions]
        }


        await this.setState({
            ...this.state,
            cidade: cidades,
            estado: estados,
            overlay_cidade: true,
            bairrosOptions: [...newBairrosOptions]
        });

        try {
            let cidade = this.state.cidade[this.state.cidade.length - 1].label;
            let estado = this.state.cidade[this.state.cidade.length - 1].value.split('-')[0];

            let address = `${cidade} ${estado} - Brasil`;

            let location = await geolocationService.location(address);

            window.googleMap.panTo({ lat: location.latitude, lng: location.longitude });

            await this.reloadEmpreendimentos();
        } catch (error) {
            console.log(error);
        }

    }


    fetchBairros = async (cidade) => {
        try {
            let bairros = await axios.get('/bairros/json/' + cidade);

            return bairros.data;
        } catch (error) {
            console.log(error);
        }
    }

    onMarkerComplete = async (marker, map, circle) => {

        let color = this.getColor();
        let newCircle = new google.maps.Circle({
            ...defaultCircleOptions,
            center: marker.getPosition(),
            radius: 1000,
            fillColor: color,
            map: window.googleMap.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED,
        });

        newCircle.color = color;
        newCircle.type = 'Círculo';
        newCircle.id = this.generateRandomString();
        newCircle.name = this.state.circles.length + 1;

        google.maps.event.addListener(newCircle, 'radius_changed', (event) => {
            console.log(event);
            this.onCircleRadiusChanged(newCircle);
        });

        google.maps.event.addListener(newCircle, 'click', (event) => {
            this.editShapeName(newCircle);
        });


        await this.setState({
            ...this.state,
            circles: [newCircle, ...this.state.circles],
            overlays: [newCircle, ...this.state.overlays]
        });


        await this.reloadEmpreendimentos();

        if (this.state.kml_bairros.length > 0) {

            await this.setState({
                ...this.state, showKMl: false
            });

            //toggle
            if (this.state.kmls_to_show.length > 0) {
                this.cleanKmlLayers();
            }

            await this.loadKml();
        }
    }

    onCircleRadiusChanged = (circle) => {
        let newRadius = circle.getRadius();

        this.setState({
            showTooltip: true, radiusChanging: newRadius
        });

        setTimeout(() => {
            this.setState({
                showTooltip: false,
            })
        }, 1000)

        this.reloadEmpreendimentos();

    }

    removeOverlay = async (id) => {

        let overlays = this.state.overlays;
        let circles = this.state.circles;
        let poligonos = this.state.polygons;
        let countPolygons = this.state.countPolygons;
        let countRectangles = this.state.countRectangles;

        let index = overlays.findIndex((overlay) => {
            return overlay.id === id
        });

        let overlay = overlays[index];


        if (overlay.type === 'Retângulo' || overlay.type === 'Polígono') {
            let indexPoligono = poligonos.findIndex((overlay) => {
                return overlay.id === id
            });
            poligonos.splice(indexPoligono, 1);
            overlay.setMap(null);

            if (overlay.type === 'Retângulo') {
                countRectangles--;
            } else {
                countPolygons--;
            }
        }

        if (overlay.type === 'Círculo' || overlay.type === 'Círculo Manual') {
            let indexCircle = circles.findIndex((overlay) => {
                return overlay.id === id
            });
            circles.splice(indexCircle, 1);


            if (overlay.setMap !== undefined) {
                overlay.setMap(null);
            }

        }

        overlays.splice(index, 1);


        await this.setState({
            overlays: [...overlays],
            manualCircles: [...circles],
            polygons: [...poligonos],
            countPolygons: countPolygons,
            countRectangles: countRectangles
        });

        if (this.state.empreendimentos.length > 0) {
            await this.reloadEmpreendimentos();
        }

    }

    handleShowInfoWindow = () => {
        this.setState({
            showInfoWindow: true
        });
    }

    handleHideInfoWindow = () => {
        this.setState({
            showInfoWindow: false
        });
    }

    getColor = () => {
        return colors[this.state.overlays.length];
    }

    resetMarkers = async () => {
        let newEmpreendimentos = [];

        await this.state.empreendimentos.map((empreendimento) => {

            empreendimento.customMarker = undefined;
            newEmpreendimentos.push(empreendimento);

        });

        this.mapBuilding(newEmpreendimentos);

        this.setState({
            ...this.state, empreendimentos: newEmpreendimentos
        });

    }
    resetRevendaMarkers = async () => {
        let newRevenda = [];

        this.state.revendaLocacao.map((empreendimento) => {
            empreendimento.customMarker = undefined;
            newRevenda.push(empreendimento);
        });

        this.mapBuilding(newRevenda);

        this.setState({
            ...this.state, revendaLocacao: newRevenda
        });

    }

    replaceRevendaMarkers = async () => {

        await this.resetRevendaMarkers();

        let newRevenda = await replaceMarkersRevendaLocacao(this.state.revendaLocacao);

        this.setState({
            ...this.state, revendaLocacao: newRevenda,
        });
    };

    //replace markers with markers from ranges
    replaceMarkers = async () => {
        //reload para para resetar os marcadores
        await this.resetMarkers();


        if (this.state.ranges.length > 0) {
            let ranges = this.state.ranges;

            let newEmpreendimentos = [];

            await this.state.empreendimentos.map((empreendimento) => {
                let range = ranges.find((range) => {
                    return empreendimento[range.type] >= range.min && empreendimento[range.type] <= range.max
                });


                if (range !== undefined) {

                    if (range.marker.type === 'image') {
                        empreendimento.customMarker = range.marker.value;
                    } else {
                        empreendimento.customMarker = range.marker;
                    }

                }

                newEmpreendimentos.push(empreendimento);

            });

            this.setState({
                ...this.state,
                empreendimentos: newEmpreendimentos
            });

        }

    }

    addRange = async (range) => {
        this.setState({
            ...this.state, ranges: [...this.state.ranges, range]
        });
    }

    removeRange = async (range) => {
        this.setState({
            ...this.state, ranges: this.state.ranges.filter(r => r.id !== range.id)
        });
    }

    handleCloseModalInfo = async () => {
        await this.setState({
            ...this.state, showInfo: false
        });
        $("#btn-results").click();
    };

    handleCloseProjectName = async () => {
        await this.setState({
            ...this.state, showProjectName: false
        });
    };

    openModalWork = async () => {
        await this.setState({
            ...this.state,
            showProjectName: true,
            left_side_bar_visible: false,
        });

        $("#btn-results").click();

    };

    closeAndAlterStatusBuilding = async () => {

        let data = this.state.empreendimentosDoMes;
        let url = window.location + `/show-new-buildings-map`;

        const headers = new Headers();
        const token = document.head.querySelector('meta[name="csrf-token"]').content;

        headers.append("X-CSRF-TOKEN", token);
        headers.append("Content-Type", "application/json");

        fetch(url, {
            method: "POST", body: JSON.stringify(data), headers: headers, credentials: "same-origin"
        })
            .then(response => response.json())
            .then(async data => {
                if (data.success == true) {
                    await this.setState({
                        ...this.state, showInfo: false
                    });
                    $("#btn-results").click();
                }
            });
    };

    /**
     * Mapeia Empreendimentos recém lançados
     * @param buildings
     * @returns {Promise<void>}
     */
    mapBuilding = async (buildings) => {

        let buildingsFromThisMonth = []
        let buildingsFiltred = [];
        let userBuildingsRejected = await axios.get(route('show-new-buildings-map', { building: this.props.building_id }));
        buildings.filter((building) => {
            if (building.data_lancamento > moment().subtract(2, 'months').clone().startOf("month").format()) {
                buildingsFromThisMonth.push(building);
            }
        });

        buildingsFromThisMonth.filter((building) => {
            if (userBuildingsRejected.data.buildings.includes(building._id) === false) {
                buildingsFiltred.push(building);
            }
        })

        if (buildingsFiltred.length > 0) {
            await this.setState({
                ...this.state, empreendimentosDoMes: buildingsFiltred, showInfo: true,
            });
            $(".p-sidebar-close").click();

            $("#btn-results").click();
        }
    }

    closeModalEmpreendimento = async () => {

        await this.setState({
            ...this.state, modal_empreendimento_visible: false, currentShowing: undefined
        });
        document.getElementById("btn-results").click();


    };

    setPolosGeradoresCurrentShowing = async (polos, polosAgrupados, cacheKey) => {

        await this.setState({
            ...this.state,
            polos_geradores_current_showing: polos,
            polos_geradores_current_grouped: polosAgrupados,
            polos_geradores_current_cache_key: cacheKey,
            show_modal_polos_geradores: true
        });

        await this.comparaPolosOnArea();

    }

    closeModalDadosSocioDemografia = async () => {
        await this.setState({
            ...this.state, show_modal_dados_socio_demografia: false
        });
    }

    loadDadosSensoDemografico = async (requestConfig) => {

        let tipoInformacao = requestConfig.tipoInformacao;

        try {
            let { polygons, cidades, hash } = this.getTransformedPayloadForSenso();


            if (this.state.dados_senso_demografico?.[hash] && this.state.dados_senso_demografico?.[hash]?.[tipoInformacao]) {
                return this.state.dados_senso_demografico?.[hash]?.[tipoInformacao];
            }

            await this.setState({
                ...this.state, loading_dados_socio_demografia: true
            });

            if (tipoInformacao === 'faixa_etaria') {

                let dataFaixaEtaria = await this.loadFaixasEtarias(polygons, cidades);

                await this.setState({
                    ...this.state,
                    dados_senso_demografico: {
                        ...this.state.dados_senso_demografico,
                        [hash]: {
                            ...this.state.dados_senso_demografico?.[hash],
                            faixa_etaria: dataFaixaEtaria
                        }
                    }
                });

                return dataFaixaEtaria;
            }

            if (tipoInformacao === 'domicilios_por_tipo_moradia' || tipoInformacao === 'indice_verticalizacao') {

                let dataDomiciliosPorTipo = await this.loadDomiciliosPorTipo(polygons, cidades);

                await this.setState({
                    ...this.state,
                    dados_senso_demografico: {
                        ...this.state.dados_senso_demografico,
                        [hash]: {
                            ...this.state.dados_senso_demografico?.[hash],
                            domicilios_tipo: dataDomiciliosPorTipo
                        }
                    }
                });

                return dataDomiciliosPorTipo;

            }

            if (tipoInformacao === 'domicilios_por_condicao_ocupacao') {
                let data = await this.loadDomiciliosPorOcupacao(polygons, cidades);

                await this.setState({
                    ...this.state,
                    dados_senso_demografico: {
                        ...this.state.dados_senso_demografico,
                        [hash]: {
                            ...this.state.dados_senso_demografico?.[hash],
                            domicilio_por_ocupacao: data
                        }
                    }
                });

                return data;
            }

            if (tipoInformacao === 'domicilios_por_numero_moradores') {


                let data = await this.loadDomiciliosPorNumeroMoradores(polygons, cidades, requestConfig.ranges);

                await this.setState({
                    ...this.state,
                    dados_senso_demografico: {
                        ...this.state.dados_senso_demografico,
                        [hash]: {
                            ...this.state.dados_senso_demografico?.[hash],
                            domicilio_por_numero_moradores: data
                        }
                    }
                });

                return data;
            }

            if (tipoInformacao === 'domicilios_por_faixa_de_renda') {
                let data = await this.loadDomiciliosPorFaixaDeRenda(polygons, cidades);

                await this.setState({
                    ...this.state,
                    dados_senso_demografico: {
                        ...this.state.dados_senso_demografico,
                        [hash]: {
                            ...this.state.dados_senso_demografico?.[hash],
                            domicilios_por_faixa_de_renda: data
                        }
                    }
                });

                return data;
            }

            if (tipoInformacao === 'populacao_por_faixa_de_renda') {
                let data = await this.loadPopulacaoPorFaixaDeRenda(polygons, cidades);

                await this.setState({
                    ...this.state,
                    dados_senso_demografico: {
                        ...this.state.dados_senso_demografico,
                        [hash]: {
                            ...this.state.dados_senso_demografico?.[hash],
                            populacao_por_faixa_de_renda: data
                        }
                    }
                });

                return data;
            }

        } catch (e) {
            console.log(e);
        } finally {
            await this.setState({
                ...this.state, loading_dados_socio_demografia: false
            });
        }
    }

    loadDomiciliosPorOcupacao = async (polygons, cidades) => {
        try {
            return await getDomiciliosPorTipoOcupacao(polygons, cidades);
        } catch (e) {
            throw e;
        }
    }

    loadDomiciliosPorTipo = async (polygons, cidades) => {
        try {
            return await getDomiciliosPorTipo(polygons, cidades);
        } catch (e) {
            throw e;
        }
    }

    loadDomiciliosPorNumeroMoradores = async (polygons, cidades, ranges) => {
        try {
            return await getDomiciliosPorNumeroMoradores(polygons, cidades, ranges);
        } catch (e) {
            throw e;
        }
    }

    loadDomiciliosPorFaixaDeRenda = async (polygons, cidades) => {
        try {
            return await getDomiciliosPorFaixaDeRenda(polygons, cidades);
        } catch (e) {
            throw e;
        }
    }

    loadPopulacaoPorFaixaDeRenda = async (polygons, cidades) => {
        try {
            return await getPopulacaoPorFaixaDeRenda(polygons, cidades);
        } catch (e) {
            throw e;
        }
    }

    loadFaixasEtarias = async (polygons, cidades) => {
        try {
            return await getFaixasEtarias(polygons, cidades);
        } catch (e) {
            throw e;
        }
    }

    loadMapaCloropetico = async (tipoMapa) => {
        try {
            debugger;
            const tiposValidos = Object.keys(configSociodemografia).filter(tipo => tipo.startsWith("mapa_coropetico"));

            if (!tiposValidos.includes(tipoMapa)) {
                throw new Error("Tipo de Mapa Inválido");
            }

            await this.setState({
                ...this.state, loading: true
            });

            let configOptions = configSociodemografia[tipoMapa];

            let { polygons, cidades } = this.getTransformedPayloadForSenso();

            let data = [];

            if (tipoMapa === "mapa_coropetico_renda_domiciliar") {
                data = await getSetoresWithHouldhouseIncome(
                    polygons, cidades, configOptions.color_ranges, configOptions.dado_range
                );
            }

            if (tipoMapa === "mapa_coropetico_indice_verticalizacao") {
                data = await getSetoresWithVerticalizationIndex(
                    polygons, cidades, configOptions.color_ranges, configOptions.dado_range
                );
            }

            if (tipoMapa === "mapa_coropetico_indice_propriedade") {
                data = await getSetoresWithPropertyIndex(
                    polygons, cidades, configOptions.color_ranges, configOptions.dado_range
                );
            }

            if (tipoMapa === 'mapa_coropetico_densidade_demografica_2010') {
                data = await getSetoresWithPopulationDensity(
                    polygons, cidades, configOptions.color_ranges, configOptions.dado_range
                );
            }

            if (tipoMapa === 'mapa_coropetico_densidade_demografica_2022') {
                data = await getSetoresWithPopulationDensityCenso2022(
                    polygons, cidades, configOptions.color_ranges, configOptions.dado_range
                );
            }

            this.setState({
                ...this.state,
                mapa_coropetico_current_showing: {
                    config: configOptions,
                    data: data,
                    type: tipoMapa
                },
                color_ranges: {
                    ...this.state.color_ranges,
                    [tipoMapa]: configOptions.color_ranges
                }
            });

            this.showIbgeInfo(tipoMapa, data);

        } catch (e) {
            console.log(e);
        } finally {
            await this.setState({
                ...this.state, loading: false
            });
        }
    }

    openModalDadosSocioDemograficos = async () => {
        $("#btn-results").click();

        await this.setState({
            ...this.state, show_modal_dados_socio_demografia: true,
            left_side_bar_visible: false
        });
    }

    render() {

        /*if( this.state.use_cluster ){
            let map = window.googleMap.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
             this.markersClusterer = new MarkerClusterer({ markers: this.markers, map: map });
        }else{
            if(this.markersClusterer ){
                this.markers.map( (marker) => {
                    this.markersClusterer.removeMarker(marker);
                });
            }
        }*/

        var data_lancamento_inicial = "";
        var data_lancamento_final = "";

        if (this.state.empreendimentosDoMes.length) {
            let empreendimentosOrdenados = this.state.empreendimentosDoMes.sort((a, b) => {
                // Converta as datas para objetos Date para comparação
                let dataA = new Date(a.data_lancamento);
                let dataB = new Date(b.data_lancamento);

                return dataA - dataB;
            });

            data_lancamento_inicial = empreendimentosOrdenados[0].data_lancamento
            data_lancamento_final = empreendimentosOrdenados[empreendimentosOrdenados.length - 1].data_lancamento
        }


        return (<>
            {this.state.loading && <Loader />}

            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{ width: "60%", color: "#000", zIndex: 9999999 }}
            />

            <ModalPolosGeradores
                handleCloseModal={this.closeModalPolosGeradores}
                show={this.state.show_modal_polos_geradores}
                polos={this.state.polos_geradores_current_showing}
                polos_geradores_agrupados={this.state.polos_geradores_current_grouped}
                cacheKey={this.state.polos_geradores_current_cache_key}
            />

            <SocioDemografiaModal
                show={this.state.show_modal_dados_socio_demografia}
                handleClose={this.closeModalDadosSocioDemografia}
                data={this.state.faixa_etaria_data}
                overlays={this.state.overlays}
                loading={this.state.loading_dados_socio_demografia}
                loadDadosSensoDemografico={this.loadDadosSensoDemografico}
                loadMapaCloropetico={this.loadMapaCloropetico}
            />

            <ModalRaioPorDeslocamento
                show={this.state.show_modal_raio_de_deslocamento}
                handleClose={this.closeModalDeslocamento}
                loading={false}
                center={this.state.marker_deslocamento_position}
                onPolygonComplete={this.onPolygonComplete}
            />

            <AlertModal
                show={this.state.showInfo}
                handleClose={this.handleCloseModalInfo}
                closeAndAlterStatusBuilding={this.closeAndAlterStatusBuilding}
            >
                <div className="row">
                    <div className="col-md-12">
                        <p className="alert alert-secondary text-justify font-weight-bold">
                            {data_lancamento_inicial != data_lancamento_final && <>
                                Aqui estão os empreendimentos lançados entre{" "}
                                {new Date(data_lancamento_inicial).toLocaleDateString("pt-BR", { year: 'numeric', month: '2-digit' })}{" "}
                                até {new Date(data_lancamento_final).toLocaleDateString("pt-BR", { year: 'numeric', month: '2-digit' })}.
                            </>}

                            {data_lancamento_inicial == data_lancamento_final && <>
                                Aqui estão os empreendimentos lançados em{" "}
                                {new Date(data_lancamento_inicial).toLocaleDateString("pt-BR", { year: 'numeric', month: '2-digit' })}{" "}
                            </>}

                        </p>
                    </div>
                    <div class="col-md-12">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Data</th>
                                    <th scope="col">Nome</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.empreendimentosDoMes.map((empreendimento, index) => {
                                    return (<tr key={`emp-mes-${index}`}>
                                        <td>{index + 1}</td>
                                        <td>
                                            {new Date(empreendimento.data_lancamento).toLocaleDateString("pt-BR", { year: 'numeric', month: '2-digit' })}
                                        </td>
                                        <td>
                                            {empreendimento.nome}
                                        </td>
                                    </tr>);
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </AlertModal>

            <ModalSalvarProjeto
                show={this.state.showProjectName}
                handleClose={this.handleCloseProjectName}
                handleNameProjectChanged={this.handleNameProjectChanged}
                project_name={this.state.project_name}
                saveProject={this.saveProject}
                loading={this.state.loadingSaveProject}
                share_users={this.state.project_share_users}
                handleShareUsersChanged={this.handleShareUsersChanged}
                errors={this.state.errors_projeto}
            />

            {this.state.mapa_coropetico_current_showing && <ModalLegendaRendaIbge
                show={this.state.show_modal_customizar_cores_setores}
                handleClose={this.closeModalLegendaSetores}
                colorRanges={this.state.color_ranges[this.state.mapa_coropetico_current_showing.type]}
                updateRange={this.updateRendaColorRange}
                removeRange={this.removeRendaColorRange}
                handleAplyColorRanges={this.handleAplyColorRanges}
            />}

            {this.state.current_shape_editing !== null &&
                <ShapesAttributesEditor
                    show={this.state.show_modal_change_shape_attributes}
                    shape={this.state.current_shape_editing}
                    onHide={() => {
                        this.setState({
                            ...this.state,
                            show_modal_change_shape_attributes: false
                        });
                    }}
                    updateShape={this.updateShape}
                />}


            {!this.state.left_side_bar_visible && <>
                <div className="map-sidebar-show-button-wrapper">
                    <button
                        title="Mostrar Barra Lateral"
                        onClick={() => this.setState({ left_side_bar_visible: true })}
                        className="btn btn-primary btn-show-map-sidebar">
                        <i className="fas fa-angle-double-right"></i>
                    </button>
                </div>
            </>}

            <Sidebar visible={this.state.left_side_bar_visible} onHide={() => this.setState({ left_side_bar_visible: false })}>
                <Tabs
                    activeKey={this.state.active_tab}
                    defaultActiveKey="filtros"
                    onSelect={(e) => {
                        this.setState({
                            ...this.state, active_tab: e
                        })
                    }}
                    id="uncontrolled-tab-example">
                    <Tab eventKey="filtros" title="Filtros">
                        <FormFilters
                            handleCidadeChanged={this.handleCidadeChangedOnFormFilter}
                            handleAreaMaximaChanged={this.handleAreaMaximaChanged}
                            handleAreaMinimaChanged={this.handleAreaMinimaChanged}
                            handlePadraoChanged={this.handlePadraoChanged}
                            handleProjetoChanged={this.handleProjetoChanged}
                            handleValorMinimoChanged={this.handleValorMinimoChanged}
                            handleValorMaximoChanged={this.handleValorMaximoChanged}
                            handleValorMetroMinimoChanged={this.handleValorMetroMinimoChanged}
                            handleValorMetroMaximoChanged={this.handleValorMetroMaximoChanged}
                            handleTipoEmpreendimentoChanged={this.handleTipoEmpreendimentoChanged}
                            handleStatusChanged={this.handleStatusChanged}
                            handleTipoChanged={this.handleTipoChanged}
                            getPadroes={this.getPadroes}
                            getProjects={this.getProjects}
                            bairrosOptions={this.state.bairrosOptions}
                            bairros={this.state.bairros}
                            handleBairrosChanged={this.handleBairrosChanged}
                            handleRaioChanged={this.handleRaioChanged}
                            tipo_empreendimento={this.state.tipo_empreendimento}
                            handleSubmit={this.reloadEmpreendimentos}
                            cleanState={this.cleanState}
                            handleQuartosChanged={this.handleQuartosChanged}
                            handleVagasChanged={this.handleVagasChanged}
                            quartos={this.state.quartos}
                            vagas={this.state.vagas}
                            area_minima={this.state.area_maior_que}
                            area_maxima={this.state.area_menor_que}
                            status={this.state.status}
                            tipo_negocio={this.state.tipo_negocio}
                            padrao={this.state.padrao}
                            projetos={this.state.projetos}
                            projeto={this.state.projeto}
                            raio={this.state.raio}
                            handleIncorporadoraChanged={this.handleIncorporadoraChanged}
                            address={this.state.address}
                            valor_parcela_minima={this.state.valor_parcela_minima}
                            valor_parcela_maxima={this.state.valor_parcela_maxima}
                            valor_maior_que={this.state.valor_maior_que}
                            valor_menor_que={this.state.valor_menor_que}
                            valor_metro_menor_que={this.state.valor_metro_menor_que}
                            valor_metro_maior_que={this.state.valor_metro_maior_que}
                            showLegenda={this.state.empreendimentos.length > 0}
                            handleAnoLancamentoChanged={this.handleAnoLancamentoChanged}
                            handleMesAnoLancamentoChanged={this.handleMesAnoLancamentoChanged}
                            handleEstagioChanged={this.handleEstagioChanged}
                            mes_ano_lancamento={this.state.mes_ano_lancamento}
                            ano_lancamento={this.state.ano_lancamento}
                            estagio={this.state.estagio}
                            handleNomeEmpreendimentoChanged={this.handleNomeEmpreendimentoChanged}
                            nome_empreendimento={this.state.nome_empreendimento}
                            searchEmpreendimentos={this.searchEmpreendimentos}
                            overlays={this.state.overlays}
                            cidade={this.state.cidade}
                            incorporators={this.state.incorporadoras}
                            downloadKml={this.downloadKml}
                            openModalWork={this.openModalWork}
                            deleteProject={this.deleteProject}
                            handleJurosMinimoChanged={this.handleJurosMinimoChanged}
                            handleJurosMaximoChanged={this.handleJurosMaximoChanged}
                            handleParcelasMinimoChanged={this.handleParcelasMinimoChanged}
                            handleParcelasMaximoChanged={this.handleParcelasMaximoChanged}
                            handleValorParcelaMinimaChanged={this.handleValorParcelaMinimaChanged}
                            handleValorParcelaMaximaChanged={this.handleValorParcelaMaximaChanged}
                            juros_maximo={this.state.juros_maximo}
                            juros_minimo={this.state.juros_minimo}
                            parcelas_maximo={this.state.parcelas_maximo}
                            parcelas_minimo={this.state.parcelas_minimo}
                            valor_parcela_maximo={this.state.valor_parcela_maximo}
                            valor_parcela_minimo={this.state.valor_parcela_minimo}
                            ranges={this.state.ranges}
                            open_modal_polos_geradores={this.openModalPolosGeradores}
                            setShowPolosGeradores={this.setShowPolosGeradores}
                            showPolosGeradores={this.state.show_polos_geradores}
                            showMarkers={this.state.showMarkers}
                            setShowMarkers={this.setShowMarkers}
                            showResaleMarkers={this.state.showResaleMarkers}
                            setShowResaleMarkers={this.setShowResaleMarkers}
                            setShowLocationMarkers={this.setShowLocationMarkers}
                            showResaleOrLocationMarkers={this.state.showResaleOrLocationMarkers}
                            showLocationMarkers={this.state.showLocationMarkers}
                            polos_geradores_types_selected={this.state.polos_geradores_types_selected}
                            setPolosGeradoresTypes={this.setPolosGeradoresTypes}


                            estadosSelecionados={this.state.estadosSelecionados}
                            handleEstadosChanged={this.handleEstadosChanged}
                            busca_por_estado={this.state.busca_por_estado}
                            setBuscaPorEstado={this.setBuscaPorEstado}
                            handlePorcentagemVendidaMaximaChanged={this.handlePorcentagemVendidaMaximaChanged}
                            handlePorcentagemVendidaMinimaChanged={this.handlePorcentagemVendidaMinimaChanged}
                            porcentagem_vendida_minima={this.state.porcentagem_vendida_minima}
                            porcentagem_vendida_maxima={this.state.porcentagem_vendida_maxima}
                            toggleUseCluster={this.toggleUseCluster}
                            use_cluster={this.state.use_cluster}
                        />

                    </Tab>
                    <Tab eventKey="ferramentas" title="Ferramentas">
                        <Ferramentas
                            onChangeInputRadius={this.onChangeInputRadius}
                            removeOverlay={this.removeOverlay}
                            editShapeName={this.editShapeName}
                            removeHeatMap={this.removeHeatMap}
                            heatMap={this.state.heatMap}
                            showMarkers={this.state.showMarkers}
                            toggleShowMarkers={this.toggleShowMarkers}
                            overlays={this.state.overlays}
                            toogleOverlayVisibility={this.toogleOverlayVisibility}
                            reloadEmpreendimentos={this.reloadEmpreendimentos}
                            removeIbgeDataLayer={this.removeIbgeDataLayer}
                            toggleIbgeDataLayerVisibility={this.toggleIbgeDataLayerVisibility}
                            ibgeDataLayer={this.state.ibgeDataLayer}
                            polos_geradores_types_selected={this.state.polos_geradores_types_selected}
                            removePolosGeradoresTypeSelectedAndReloadEmpreendimentos={this.removePolosGeradoresTypeSelectedAndReloadEmpreendimentos}
                            createAndAddPolygon={this.createAndAddPolygon}
                            downloadKml={this.downloadKml}
                            setShowMarkers={this.setShowMarkers}
                            toggleUseCluster={this.toggleUseCluster}
                            use_cluster={this.state.use_cluster}
                            openModalWork={this.openModalWork}

                        />
                    </Tab>
                    <Tab eventKey="legendas" title="Legendas">
                        <div style={{ height: '90vh', overflowY: 'auto' }}>
                            {this.state.showRenda && <RendaIbgeLegend
                                openModalLegendas={this.openModalLegendaSetores}
                                colorRanges={this.state.color_ranges[this.state.mapa_coropetico_current_showing.type]}
                                mapa_coropetico_current_showing={this.state.mapa_coropetico_current_showing}
                            />}

                            {this.state.heatMap && <>
                                <HeatMapLegend
                                    weightType={this.state.heatMapWeightType}
                                    gradient={this.getGradientConfig()}
                                    min={this.state.minIntensity}
                                    max={this.state.maxIntensity}
                                    medium={this.state.medianIntensity}
                                    config={this.state.heatMapLegendConfig}
                                    legend={this.state.heatMapLegendConfig.legend}
                                />
                            </>}
                            {this.state.empreendimentos.length > 0 && (
                                <Legenda ranges={this.state.ranges} />
                            )}

                        </div>

                    </Tab>
                </Tabs>
            </Sidebar>

            <div className="col-12" id="content" style={{ position: "relative" }}>
                {this.state.empreendimentos.length > 0 && (<SlidingPaneEmpreendimento
                    setLoading={this.setLoading}
                    closePane={this.closePane}
                    loadingMetadata={this.state.loadingMetadata}
                    isOpen={this.state.isSlideOpen}
                    empreendimento={this.state.currentShowing}
                    showEmprendimentoCard={this.state.panel_empreendimento_visible}
                    showModalEmpreendimento={this.state.modal_empreendimento_visible}
                    closeModalEmpreendimento={this.closeModalEmpreendimento}
                    handleHideClicked={this.handleHideClicked}
                    overlays={this.state.overlays}
                    togglePainelEmpreendimento={this.togglePainelEmpreendimento}
                    loading_empreendimento={this.state.loading_empreendimento}
                    empreendimentos={this.state.empreendimentos}
                    ranges={this.state.ranges}
                    replaceMarkers={this.replaceMarkers}
                    addRange={this.addRange}
                    removeRange={this.removeRange}
                    showKMl={this.state.showKMl}
                    kmlFunction={this.kmlFunction}
                    kml_bairros={this.state.kml_bairros}
                    addHeatMap={this.addHeatMap}
                    setHeatMapWeightType={this.setHeatMapWeightType}
                    show_polos_geradores={this.state.show_polos_geradores}
                    setPolosGeradoresCurrentShowing={this.setPolosGeradoresCurrentShowing}
                    cidades={this.state.cidade}
                    estados={this.state.estado}
                    editShapeName={this.editShapeName}
                    showIbgeInfo={this.showIbgeInfo}
                    showRenda={this.state.showRenda}
                    rendasPorArea={this.state.rendas_por_area}
                    originalState={this.state}
                    openModalDadosSocioDemograficos={this.openModalDadosSocioDemograficos}
                />)}
                <MapWithADrawingManager
                    onMarkerComplete={this.onMarkerComplete}
                    currentPosition={this.state.currentPosition}
                    onPolygonComplete={this.onPolygonComplete}
                    onCircleComplete={this.onCircleComplete}
                    empreendimentos={this.state.empreendimentos}
                    onRectangleComplete={this.onRectangleComplete}
                    onMarkerClick={this.onMarkerClick}
                    onRevendaMarkerClick={this.onRevendaMarkerClick}
                    manualCircles={this.state.manualCircles}
                    raio={this.state.raio}
                    zoom={this.state.zoom}
                    setLocation={this.setLocation}
                    pontos={this.state.pontos}
                    getColor={this.getColor}
                    fillColor={this.state.fillColor}
                    selectedIcon={this.state.selectedIcon}
                    showInfoWindow={this.showInfoWindow}
                    handleShowInfoWindow={this.handleShowInfoWindow}
                    handleHideInfoWindow={this.handleHideInfoWindow}
                    showKMl={this.state.showKMl}
                    kml_layers={this.state.kmls_to_show}
                    showMarkers={this.state.showMarkers}
                    show_polos_geradores={this.state.show_polos_geradores}
                    showResaleMarkers={this.state.showResaleMarkers}
                    showResaleOrLocationMarkers={this.state.showResaleOrLocationMarkers}
                    showLocationMarkers={this.state.showLocationMarkers}
                    polos_geradores={this.state.polos_geradores}
                    revendaLocacao={this.state.revendaLocacao}
                    editShapeName={this.editShapeName}
                    use_cluster={this.state.use_cluster}
                    currentShowing={this.state.currentShowing}
                    onCenterMarkClick={this.showModalDeslocamento}

                />

                <MouseTooltip
                    visible={this.state.showTooltip}
                    offsetX={15}
                    offsetY={10}
                >
                    <span
                        style={{
                            fontWeight: "bold",
                            padding: "10px",
                            backgroundColor: "green",
                            color: "#ffffff",
                            borderRadius: "5pu",
                        }}
                    >
                        {Number(this.state.radiusChanging).toFixed(2)}{" "}
                        metros
                    </span>
                </MouseTooltip>
            </div>
            {this.state.showRenda && <RendaIbgeLegend />}
        </>);
    }

    //verifica se tem formas desenhadas no mapa
    thereAreDrownShapes() {
        let countFormas = this.state.polygons.length + this.state.circles.length;

        if (countFormas === 0) {
            return false;
        }

        let count = 0;

        this.state.circles.forEach((circle) => {
            if (circle.getMap() !== null) {
                count++;
            }
        });

        this.state.polygons.forEach((polygon) => {
            if (polygon.getMap() !== null) {
                count++;
            }
        });

        return count > 0;
    }


    async getKmlBairros(cidade, uf) {
        try {
            //verifica se o kml da cidade já existe no this.state.kml_bairros
            let kmlCidade = this.state.kml_bairros.find(kml => kml.cidade === cidade && kml.uf === uf);

            if (kmlCidade) {
                return;
            }

            let response = await axios.get(route('bairros.kml', { cidade: cidade, estado: uf }));
            let kmlBairros = this.state.kml_bairros;
            kmlBairros[cidade] = response.data.kml;

            if (response.data.kml !== null && response.data.kml !== undefined && response.data.kml !== '') {
                this.setState({
                    ...this.state, kml_bairros: [...this.state.kml_bairros, {
                        cidade: cidade, kml: response.data.kml, uf: uf
                    }]
                })

                //await axios.get( response.data.kml );
            }

        } catch (e) {
            console.log("Erro ao buscar kml de bairros", e);
            console.log(e)
        }
    }

    //limpa kmls de bairros
    cleanKmlLayers() {
        this.state.kml_layers.forEach((kml_layer) => {
            try {
                if (kml_layer.setMap !== undefined) {
                    kml_layer.setMap(null);
                }
            } catch (error) {
                console.log(error);
            }
        });

        this.setState({
            ...this.state, kml_layers: [], kmls_to_show: []
        })
    }

    getGradientConfig() {
        return ["rgba(0, 255, 255, 0)", "rgba(0, 255, 255, 1)", "rgba(0, 191, 255, 1)", "rgba(0, 127, 255, 1)", "rgba(0, 63, 255, 1)", "rgba(0, 0, 255, 1)", "rgba(0, 0, 223, 1)", "rgba(0, 0, 191, 1)", "rgba(0, 0, 159, 1)", "rgba(0, 0, 127, 1)", "rgba(63, 0, 91, 1)", "rgba(127, 0, 63, 1)", "rgba(191, 0, 31, 1)", "rgba(255, 0, 0, 1)",

        ];
    }

    openModalPolosGeradores = async () => {
        this.setState({
            ...this.state, show_modal_polos_geradores: true
        })
    }

    closeModalPolosGeradores = async () => {
        this.setState({
            ...this.state, show_modal_polos_geradores: false
        })
    }

    setShowPolosGeradores = async (show_polos_geradores) => {

        this.setState({
            ...this.state, show_polos_geradores: show_polos_geradores
        })
    }

    setShowMarkers = async (showMarkers) => {
        this.setState({
            ...this.state, showMarkers: showMarkers
        })
    }

    setShowResaleMarkers = async (showResaleMarkers) => {

        let tipo_negocio = []

        if (showResaleMarkers) {
            tipo_negocio.push({ value: "Venda", label: "Revenda" });
        }

        this.state.tipo_negocio.forEach((tipo) => {
            if (tipo.value != tipo_negocio[0]?.value && tipo.value != "Venda") {
                tipo_negocio.push(tipo)
            }


        })

        await this.setState({
            ...this.state,
            showResaleMarkers: showResaleMarkers,
            showResaleOrLocationMarkers: showResaleMarkers,
            tipo_negocio: tipo_negocio
        })

    }

    setShowLocationMarkers = async (showLocationMarkers) => {

        let tipo_negocio = [];

        if (showLocationMarkers) {
            tipo_negocio.push({ value: "Aluguel", label: "Locação" });
        }
        this.state.tipo_negocio.forEach((tipo) => {
            if (!tipo.value != tipo_negocio[0]?.value && tipo.value != "Aluguel") {
                tipo_negocio.push(tipo)
            }


        })

        await this.setState({
            ...this.state,
            showLocationMarkers: showLocationMarkers,
            showResaleOrLocationMarkers: showLocationMarkers,
            tipo_negocio: tipo_negocio
        })
    }


    setPolosGeradoresTypes = async (polos_geradores_types) => {

        if (polos_geradores_types == null) {
            polos_geradores_types = []
        }

        this.setState({
            ...this.state,
            polos_geradores_types_selected: polos_geradores_types
        })
    }

    getPolosGeradoresTypesAsArray = () => {
        let polos_geradores_types = [];

        if (this.state.polos_geradores_types_selected == null) {
            return polos_geradores_types;
        }

        this.state.polos_geradores_types_selected.forEach((polo_gerador_type) => {
            polos_geradores_types.push(polo_gerador_type.value);
        })

        return polos_geradores_types;
    }

    removePolosGeradoresTypeSelectedAndReloadEmpreendimentos = async (index) => {
        let polos_geradores_types_selected = this.state.polos_geradores_types_selected;

        polos_geradores_types_selected.splice(index, 1);

        await this.setState({
            ...this.state,
            polos_geradores_types_selected: [...polos_geradores_types_selected]
        });

        await this.reloadEmpreendimentos();
    }

    comparaPolosOnArea = async () => {

        let payload = [];

        this.state.overlays.forEach((area) => {

            if (area.polos_geradores_cache_key === '' || area.polos_geradores_cache_key === undefined) {
                return;
            }

            let areaName = '';

            if (area.name !== undefined) {
                areaName = area.name;
            }

            payload.push({
                cache_key: area.polos_geradores_cache_key, area: area.type.split(' ')[0] + ' ' + areaName
            });

        });


    }

    setBuscaPorEstado = (e) => {
        this.setState({
            ...this.state, busca_por_estado: e.target.checked
        })
    }


    removeIbgeDataLayer = async () => {

        if (!this.state.ibgeDataLayer) {
            return;
        }

        console.log('Chegou aqui');

        this.state.ibgeDataLayer?.setMap(null);

        let map = window.googleMap.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;

        for (let i = 0; i < this.state.ibgeDataLayer; i++) {
            map.data.remove(this.state.ibgeDataLayer[i]);
        }

        window.ibge_layer = null;

        await this.setState({
            ...this.state,
            ibgeDataLayer: null,
            ibge_info: null,
            showRenda: false,
        });

    }

    toggleIbgeDataLayerVisibility = async () => {

        if (!this.state.ibgeDataLayer) {
            return;
        }

        let map = window.googleMap.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;

        if (this.state.ibgeDataLayer?.getMap() === null) {
            this.state.ibgeDataLayer?.setMap(map);

            this.setState({
                ...this.state,
            });


        } else {
            this.state.ibgeDataLayer?.setMap(null);

            this.setState({
                ...this.state,
            });
        }

    }

    showModalDeslocamento = async (markerPosition) => {
        this.setState({
            ...this.state,
            show_modal_raio_de_deslocamento: true,
            marker_deslocamento_position: markerPosition
        });

        $("#btn-results").click();
    }

    closeModalDeslocamento = async () => {
        this.setState({
            ...this.state,
            show_modal_raio_de_deslocamento: false
        });
    }

    toogleOverlayVisibility = async (overlay) => {

        if (overlay.getMap() == null) {
            overlay.setMap(window.googleMap.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED);
        } else {
            overlay.setMap(null);
        }

        await this.reloadEmpreendimentos();
    }

    getPolygonBounds = async (polygon) => {

        let bounds = new google.maps.LatLngBounds();
        polygon.getPaths().forEach(function (path) {
            path.forEach(function (latlng) {
                bounds.extend(latlng);
            });
        });

        return bounds;
    }

    fitBounds = (bounds) => {
        let map = window.googleMap.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
        map.fitBounds(bounds);
    }

    handleShareUsersChanged = async (share_users) => {
        this.setState({
            ...this.state, project_share_users: share_users
        });
    }

}

export default DefaultMap;
