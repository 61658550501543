const formatNumber = (number) => {
    if (isNaN(number)) {
        number = 0;
    }
    return new Intl.NumberFormat("pt-BR", {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    }).format(number);
};

const formatNumberAsPercentage = (number) => {
    if (isNaN(number)) {
        number = 0;
    }
    return new Intl.NumberFormat("pt-BR", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    }).format(number);
};

const formatPeriodString = (period, response_period_type) => {
    if (typeof period === "number") {
        period = period.toString();
    }

    if (response_period_type === "mensal") {
        let periodsParts = period.split("-");
        return `${periodsParts[1]}/${periodsParts[0]}`;
    }

    if (response_period_type === "trimestre") {
        let periods = period.split("-");
        return `${periods[0]}T/${periods[1]}`;
    }

    if (response_period_type === "semestre") {
        let periods = period.split("-");

        return `${periods[0]}S/${periods[1]}`;
    }

    if (response_period_type === "anual") {
        return period;
    }
};

const formatTipologia = (tipologia) => {
    return tipologia;
};

module.exports = {
    formatNumber,
    formatNumberAsPercentage,
    formatPeriodString,
    formatTipologia,
}

